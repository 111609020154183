import React, { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";

import { Button, message, Table, Tooltip, Switch } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import customAxios from "../../utils/axios";

import { SERVER_URL } from "../../Constants";

const UserSubscription = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formRecords, setFormRecords] = useState({});
  const [filterStatus, setFilterStatus] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "ID",
      dataIndex: "subscription_id",
      key: "subscription_id",
      render: (subscription_id) => <div>{subscription_id}</div>,
    },
    {
      title: "Plan Title",
      dataIndex: "plan_title",
      key: "plan_title",
      render: (plan_title) => <div>{plan_title}</div>,
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      render: (status, values) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {status === "Active" ? (
            <Tooltip
              placement="top"
              title={`Subscribed at ${values?.created_at?.slice(0, -13)}`}
            >
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "24px" }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              placement="top"
              title={`Canceled at ${values?.cancellation_at?.slice(0, -13)}`}
            >
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: "24px" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Last, next (invoice)
        </span>
      ),
      dataIndex: "last_invoice_at",
      key: "last_invoice_at",
      render: (last_invoice_at, values) => (
        <>
          <div style={{ textAlign: "center" }}>
            {last_invoice_at ? last_invoice_at?.slice(0, -13) : "-"}
          </div>
          <div style={{ textAlign: "center" }}>
            {values.next_invoice ? values.next_invoice?.slice(0, -13) : "-"}
          </div>
        </>
      ),
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      render: (frequency) => <div>{frequency}</div>,
    },
    {
      title: "User ID",
      dataIndex: "user_id",
      key: "user_id",
      render: (user_id, values) => (
        <div>
          <Tooltip
            placement="top"
            title={
              values?.username && values?.user_email ? (
                <>
                  <div>Username: {values?.username}</div>
                  <div>{values?.user_email}</div>
                </>
              ) : (
                <div>User deleted!</div>
              )
            }
          >
            {user_id}
          </Tooltip>
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Action
        </span>
      ),
      key: "action",
      width: "10%",
      render: (_text, record) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ marginTop: 15 }}
            type="primary"
            danger
            onClick={(event) => {
              editPlanDetail(event, record);
            }}
            title={"Edit plan"}
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    customAxios
      .get(SERVER_URL + `/subscriptions${`?status=${filterStatus}`}`)
      .then(function (res) {
        setDataSource(res.data);
        setLoading(false);
      });
  }, [filterStatus]);

  const openEditModal = () => {
    setIsModalOpen(true);
  };

  const editPlanDetail = (event, record) => {
    event.stopPropagation();
    setIsEdit(true);

    setFormRecords(record);
    openEditModal();
  };

  const onChange = () => {
    setFilterStatus((prev) => !prev);
  };

  return (
    <div>
      {contextHolder}
      Status:{" "}
      <Switch
        checkedChildren="Active"
        unCheckedChildren="All"
        checked={filterStatus}
        onChange={onChange}
      />
      <br />
      <br />
      {loading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataSource}
          rowKey="id"
        />
      )}
    </div>
  );
};

export default UserSubscription;
