import React, { useEffect } from "react";
import { Select } from "antd";

const SelectInfluencerDropDown = (props) => {
  const allInfluencersOption = { label: "All Influencers", value: "all" };

  const options = [
    ...props.options.map((item) => ({ label: item, value: item })),
    allInfluencersOption,
  ];

  useEffect(() => {
    // Check if props.selectedInfluencer is empty or not provided, set it to "all" by default
    if (!props.selectedInfluencer) {
      props.setSelectedInfluencer(["all"]);
    }
  }, [props.selectedInfluencer, props.setSelectedInfluencer]);

  const handleSelect = (selectedInfluencers) => {
    if (selectedInfluencers.includes("all") && selectedInfluencers.length > 1) {
      // If "All Influencers" is selected along with others, remove the "all" option from the selection
      selectedInfluencers = selectedInfluencers.filter((item) => item !== "all");
    } else if (!selectedInfluencers.includes("all") && selectedInfluencers.length === props.options.length) {
      // If all other influencers are selected, set the selected influencers to only "all"
      selectedInfluencers = ["all"];
    }
    props.setSelectedInfluencer(selectedInfluencers); // Pass the selected influencers to the parent component
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Select
        value={props.selectedInfluencer}
        style={{ width: 170 }}
        onChange={handleSelect}
        options={options}
        mode="multiple"
      />
    </div>
  );
};

export default SelectInfluencerDropDown;









