import {Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const PrescriptiveMode = (formItemLayout) => {
  return (
    <>
      <h2>Prescriptive Mode:</h2>
      <Form.Item
        {...formItemLayout}
        name="prescriptive_mode_enabled"
        label="Enable or disable the Prescriptive mode. When enabled, the bot will only respond with the provided responses."
      >
        <select name="prescriptive_mode_enabled" id="prescriptive_mode_enabled" style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="prescriptive_mode_delay"
        label="The delay to get the response in the Prescriptive mode."
      >
        <Input type="number" placeholder="The delay to get the response in the Prescriptive mode."/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="prescriptive_mode_data"
        label="The data used in the Prescriptive mode. This will be used to generate responses."
        rules={[{
          required: false,
          message: "The data used in the Prescriptive mode. This will be used to generate responses."
        }]}
      >
        <TextArea rows={32}
                  placeholder="The data used in the Prescriptive mode. This will be used to generate responses."/>
      </Form.Item>
    </>
  )
}

export default PrescriptiveMode;
