import { message } from "antd";
import BarLoader from "react-spinners/BarLoader";
import React, { useEffect, useState } from "react";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";

const DemoMessagesSection = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(true);
  const [amtDemoMessages, setAmtDemoMessages] = useState(1);
  const [amtVoiceMessages, setAmtVoiceMessages] = useState(1);
  const [limitReachedDemoMessage, setLimitReachedDemoMessage] = useState("");

  const changeState = (e) => {
    setAmtDemoMessages(e.target.value);
  };

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/demo_messages").then(function (res) {
        const { data } = res;
        console.log(data);

        setAmtDemoMessages(data.demo_msg_amt);
        setAmtVoiceMessages(data.demo_voice_msg_amt);
        setLimitReachedDemoMessage(data.demo_msg_limit_reached_msg);

        setLoading(false);
      });
    };

    fetchData();
  }, []);

  const saveDemoMessages = async () => {
    customAxios
      .put(SERVER_URL + "/demo_messages", {
        demo_msg_amt: amtDemoMessages,
        demo_voice_msg_amt: amtVoiceMessages,
        demo_msg_limit_reached_msg: limitReachedDemoMessage,
      })
      .then(function (res) {
        const { data } = res;

        if (data.status === "success") {
          messageApi.open({
            type: "success",
            content: "Demo messages saved successfully!",
          });
        } else {
          messageApi.open({
            type: "error",
            content: "Error saving demo messages",
          });
        }
      });
  };

  return (
    <div>
      {contextHolder}

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <div>
          <h1>Demo Message Settings</h1>
          <div>Number of total messages allowed</div>
          <input
            min={0}
            type="number"
            placeholder="Amount of free messages"
            defaultValue={amtDemoMessages}
            onChange={({ target }) => setAmtDemoMessages(target.value)}
          />
          <div>Number of voice messages allowed</div>
          <input
            min={0}
            type="number"
            placeholder="Amount of free messages"
            defaultValue={amtVoiceMessages}
            onChange={({ target }) => setAmtVoiceMessages(target.value)}
          />

          <div>Limit reached demo message</div>
          <input
            defaultValue={limitReachedDemoMessage}
            style={{ width: "100%" }}
            type="text"
            placeholder="Limit reached Message"
            onChange={({ target }) => setLimitReachedDemoMessage(target.value)}
          />
          <button onClick={saveDemoMessages}>Save Demo Message Settings</button>
        </div>
      )}
    </div>
  );
};

export default DemoMessagesSection;
