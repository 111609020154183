import axios from "axios";

let token = window.localStorage.getItem("accessToken");

const api = axios.create();
api.defaults.headers.common.Authorization = "Bearer " + token;

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status > 400 && error?.response?.status < 500) {
      window.location.pathname = "/login";
      window.localStorage.clear();
      api.defaults.headers.common.Authorization = "";
    } else {
      throw error;
    }
  }
);

export default api;
