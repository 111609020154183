import {Form, Input} from "antd";
import React from "react";

const VoiceGenerationSettings = (formItemLayout) => {
  return (
    <>
      <h2>Voice Generation Settings:</h2>
      <Form.Item
        {...formItemLayout}
        name="voice_similarity_boost"
        label="Voice Similarity Boost"
      >
        <Input type="number" placeholder="Please input Voice Similarity Boost"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="voice_stability"
        label="Voice Stability"
      >
        <Input type="number" placeholder="Please input Voice Stability"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="voice_style"
        label="Voice Style"
      >
        <Input type="text" min={1} placeholder="Please input Voice Style"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="voice_use_speaker_boost"
        label="Voice Use Speaker Boost"
      >
        <select name="voice_use_speaker_boost" id="voice_use_speaker_boost" style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="noVoiceErrorMsg"
        label="No Voice Error Message"
      >
        <Input type="text" placeholder="Please input No Voice Error Message"/>
      </Form.Item>
    </>
  )
}

export default VoiceGenerationSettings;
