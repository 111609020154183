import React, { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { Button, message, Modal, Table, Image } from "antd";
import customAxios from "../../utils/axios";
import { SERVER_URL, FRONT_END_URL } from "../../Constants";
import axios from "../../utils/axios";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import PlansForm from "./PlansForm";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

const SubscriptionPlans = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [formRecords, setFormRecords] = useState({});

  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, values) =>
        values.is_active ? (
          <div>
            <a
              href={`${FRONT_END_URL}special-offer/${values.page_slug}`}
              target="_blank"
              rel="noreferrer"
            >
              {text}
            </a>
          </div>
        ) : (
          text
        ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Price, tkn (first, then)
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      width: "20%",
      render: (text, values) => (
        <>
          <div style={{ textAlign: "center" }}>
            ${text}, {values.token_amount} t.
          </div>
          <div style={{ textAlign: "center" }}>
            ${values.afterward_amount},{" "}
            {values.afterward_token_amount > 0
              ? values.afterward_token_amount
              : values.token_amount}{" "}
            t.
          </div>
        </>
      ),
    },
    {
      title: "Freq.",
      dataIndex: "frequency",
      key: "frequency",
      width: "5%",
      render: (text) => <div>{text}</div>,
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Is active
        </span>
      ),
      dataIndex: "is_active",
      key: "is_active",
      width: "9%",
      render: (text) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {text === 1 ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "24px" }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: "24px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Is modal
        </span>
      ),
      dataIndex: "show_in_subscription_popup",
      key: "show_in_subscription_popup",
      width: "10%",
      render: (text) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {text === 1 ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "24px" }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: "24px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Pin-tour
        </span>
      ),
      dataIndex: "is_pin_tour",
      key: "is_pin_tour",
      width: "9%",
      render: (text) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {text === 1 ? (
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "24px" }}
            />
          ) : (
            <CloseCircleTwoTone
              twoToneColor="#eb2f96"
              style={{ fontSize: "24px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>Image</span>
      ),
      dataIndex: "image_url",
      key: "image_url",
      width: "10%",
      render: (image_url, values) =>
        image_url ? (
          <Image
            width={100}
            src={image_url}
            style={{ filter: values.is_active ? "" : "grayscale(100%)" }}
          />
        ) : (
          <div>No preview</div>
        ),
    },
    {
      title: (
        <span style={{ display: "flex", justifyContent: "center" }}>
          Action
        </span>
      ),
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {/*<Button
            style={{ marginTop: 0 }}
            type="primary"
            danger
            onClick={(event) => {
              editPlanDetail(event, record, "specialOffer");
            }}
            title={"Edit special offer"}
          >
            <TagOutlined />
          </Button>*/}
          <Button
            style={{ marginTop: 15 }}
            type="primary"
            danger
            onClick={(event) => {
              editPlanDetail(event, record, "planDetails");
            }}
            title={"Edit plan"}
          >
            <EditOutlined />
          </Button>
          <Button
            style={{ marginTop: 15 }}
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id);
            }}
            title={"Delete plan"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    customAxios.get(SERVER_URL + "/subscription/plans").then(function (res) {
      setDataSource(res.data);
      setLoading(false);
    });
  }, []);

  const openPlanModal = () => {
    setIsPlanModalOpen(true);
  };

  const editPlanDetail = (event, record, type) => {
    event.stopPropagation();
    setIsEdit(true);

    setFormRecords(record);

    if (type === "planDetails") {
      openPlanModal();
    }
  };

  const handleOpenDeleteModal = (item_id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(item_id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  };

  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(SERVER_URL + "/subscription/plans", {
        data: {
          delete_id: deleteId,
        },
      });
      setDataSource(data);

      messageApi.open({
        type: "success",
        content: "Plan deleted successfully!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={openPlanModal}>
          Add New Plan
        </Button>
      </div>

      {loading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataSource}
          rowKey="id"
        />
      )}

      <PlansForm
        isPlanModalOpen={isPlanModalOpen}
        isEdit={isEdit}
        formRecords={formRecords}
        setFormRecords={setFormRecords}
        uploading={uploading}
        setUploading={setUploading}
        setDataSource={setDataSource}
        setIsPlanModalOpen={setIsPlanModalOpen}
        setIsEdit={setIsEdit}
      />

      <Modal
        title={"Plan deletion"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Are you sure you want delete current plan?</div>
      </Modal>
    </div>
  );
};

export default SubscriptionPlans;
