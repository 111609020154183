import { Table, Button, Modal, Input, Form, Select, Tag, message } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import customAxios from "../../utils/axios";
import axios from "axios";
import { countries } from "../../Constants";

import DownloadCSV from "../../Components/DownloadCSV";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;

const Pricing = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [data, setData] = useState([
    {
      text_returned: "",
      voice_returned: "",
      image_returned: "",
      credit_value: "",
      custom_image_cost: "",
      nsfw_custom_image_cost: "",
    },
  ]);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddMsgModalOpen, setIsAddMsgModalOpen] = useState(false);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const sendMessageOptions = useMemo(
    () =>
      data
        .map((item) => ({ value: item.chat_id, label: item.username }))
        .filter((item) => item.value !== null),
    [data]
  );
  const [messageApi, contextHolder] = message.useMessage();

  const formItemLayout = null;

  console.log("updating console to redeploy");

  const columns = [
    {
      title: "Text Cost",
      dataIndex: "text_returned",
      key: "text_returned",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Voice Cost Per Minute",
      dataIndex: "voice_returned",
      key: "voice_returned",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Image Cost",
      dataIndex: "image_returned",
      key: "image_returned",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "SFW Custom Image Cost",
      dataIndex: "custom_image_cost",
      key: "custom_image_cost",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "NSFW Custom Image Cost",
      dataIndex: "nsfw_custom_image_cost",
      key: "nsfw_custom_image_cost",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Credits per dollar",
      dataIndex: "credit_value",
      key: "credit_value",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Start Credit Amount",
      dataIndex: "start_credit_amount",
      key: "start_credit_amount",
      render: (text) => <div>{text}</div>,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   width: "10%",
    //   render: (text, record) => (
    //     <div style={{ display: "flex", gap: "0.5rem" }}>
    //       <Button
    //         type="primary"
    //         danger
    //         onClick={() => {
    //           editDetail(record);
    //         }}
    //       >
    //         <EditOutlined />
    //       </Button>
    //     </div>
    //   )
    // }
  ];

  function editDetail(record) {
    console.log("here is record", record);
    form.setFieldsValue({
      text_returned: record.text_returned,
      voice_returned: record.voice_returned,
      image_returned: record.image_returned,
      credit_value: record.credit_value,
      custom_image_cost: record.custom_image_cost,
      nsfw_custom_image_cost: record.nsfw_custom_image_cost,
      start_credit_amount: record.start_credit_amount,
    });
    showEditMsgModal();
  }

  useEffect(() => {
    getMsgData();
  }, []);

  const getMsgData = () => {
    customAxios.get(SERVER_URL + "/price").then(function (res) {
      setData(res.data);
      setLoading(false);
    });
  };

  const showEditMsgModal = () => {
    setIsEditModalOpen(true);
  };

  const handleMsgOk = async () => {
    try {
      const messages = form.getFieldsValue();

      const { data } = await customAxios.put(SERVER_URL + "/price", {
        ...messages,
      });
      setData(data);

      setIsEditModalOpen(false);
    } catch (error) {
      console.log("--ERROR--", error);
    } finally {
      clearFormData();
    }
  };
  const handleUserCancel = () => {
    setIsEditModalOpen(false);
    clearFormData();
  };

  function clearFormData() {
    form.setFieldsValue({
      username: "",
      age: null,
      location: "",
    });
  }

  const handleMsgCancel = () => {
    setIsAddMsgModalOpen(false);
  };

  return (
    <div>
      {contextHolder}
      <div></div>
      {/* <div>
        <Table style={{ marginTop: "8px" }} columns={columns} dataSource={data} rowKey="id" />
      </div> */}

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {columns.map((column) => (
            <div
              key={column.key}
              style={{
                margin: "10px",
                padding: "10px",
                border: "1px solid gray",
              }}
            >
              <h3>{column.title}</h3>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {data.map((item) => (
                  <li key={item.key}>
                    {column.render(item[column.dataIndex])}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div style={{ margin: "10px", padding: "10px" }}>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {data.map((item) => (
                <li key={item.key} style={{ margin: "5px" }}>
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      editDetail(item);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <Modal
        title={"Edit user"}
        open={isEditModalOpen}
        onOk={handleMsgOk}
        onCancel={handleUserCancel}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name={"text_returned"}
            label="Text"
            rules={[{ required: true, message: "Please input text price" }]}
          >
            <Input placeholder="Please input text price" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name={"voice_returned"}
            label="Voice"
            rules={[
              { required: true, message: "Please input your Voice price" },
            ]}
          >
            <Input placeholder="Please input link price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="image_returned"
            label="Image"
            rules={[{ required: true, message: "Please input image price" }]}
          >
            <Input placeholder="Please input image price" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="custom_image_cost"
            label="Custom Image"
            rules={[
              { required: true, message: "Please input custom image price" },
            ]}
          >
            <Input placeholder="Please input custom image price" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="nsfw_custom_image_cost"
            label="NSFW Custom Image"
            rules={[
              {
                required: true,
                message: "Please input nsfw custom image price",
              },
            ]}
          >
            <Input placeholder="Please input nsfw custom image price" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="credit_value"
            label="Credits per value"
            rules={[
              { required: true, message: "Please input credit per dollar" },
            ]}
          >
            <Input placeholder="Please input credit value" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="start_credit_amount"
            label="Start Credit Amount"
            rules={[
              { required: true, message: "Please input start credit amount" },
            ]}
          >
            <Input placeholder="Please input start credit amount" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Pricing;
