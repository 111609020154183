import {Form, Input} from "antd";
import React from "react";

const SecretMenu = (formItemLayout) => {
  return (
    <>
      <h2>Secret Menu:</h2>
      <h3>Secret Menu Item 1</h3>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_title1"
        label="Secret Menu Title"
        rules={[{required: false, message: "Please input a Secret Menu Title"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Title"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_link1"
        label="Secret Menu Link"
        rules={[{required: false, message: "Please input a Secret Menu Link"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Link"/>
      </Form.Item>
      <h3>Secret Menu Item 2</h3>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_title2"
        label="Secret Menu Title"
        rules={[{required: false, message: "Please input a Secret Menu Title"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Title"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_link2"
        label="Secret Menu Link"
        rules={[{required: false, message: "Please input a Secret Menu Link"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Link"/>
      </Form.Item>
      <h3>Secret Menu Item 3</h3>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_title3"
        label="Secret Menu Title"
        rules={[{required: false, message: "Please input a Secret Menu Title"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Title"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_link3"
        label="Secret Menu Link"
        rules={[{required: false, message: "Please input a Secret Menu Link"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Link"/>
      </Form.Item>
      <h3>Secret Menu Item 4</h3>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_title4"
        label="Secret Menu Title"
        rules={[{required: false, message: "Please input a Secret Menu Title"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Title"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="secret_menu_link4"
        label="Secret Menu Link"
        rules={[{required: false, message: "Please input a Secret Menu Link"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Secret Menu Link"/>
      </Form.Item>
    </>
  )
}

export default SecretMenu;
