import {Button, Form, Image, Input, message, Modal, Select, Table, Upload} from "antd";
import BarLoader from "react-spinners/BarLoader";
import React, {useEffect, useState} from "react";
import {DeleteOutlined, EditOutlined, UploadOutlined} from "@ant-design/icons";
import customAxios from "../../utils/axios";
import {SERVER_URL} from "../../Constants";
import axios from "../../utils/axios";


const BonusContentHintSection = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(true);
  const [bonusHintMsgMin, setBonusHintMsgMin] = useState(1);
  const [bonusHintMsgMax, setBonusHintMsgMax] = useState(1);

    const changebonusHintMsgMin = (e) => {
        setBonusHintMsgMin(e.target.value);
    }

    const changebonusHintMsgMax = (e) => {
        setBonusHintMsgMax(e.target.value);
    }

    useEffect(() => {
      // Check if min greater than max and if yes swap them
      if (bonusHintMsgMin > bonusHintMsgMax) {

        let temp = bonusHintMsgMin;

        setBonusHintMsgMin(bonusHintMsgMax);
        setBonusHintMsgMax(temp);
      }
    }, [bonusHintMsgMin, bonusHintMsgMax]);

    useEffect(() => {
      const fetchData = () => {
        customAxios.get(SERVER_URL + "/bonus_content_hint_msg").then(function (res) {
          const { data } = res;
          console.log(data);

          setBonusHintMsgMin(data.bonusHintMsgMin);
          setBonusHintMsgMax(data.bonusHintMsgMax);

          setLoading(false);
        });
      }
  
      fetchData();
  
    }, []);

  const saveBonusContentHintMsg = async () => {
    customAxios.put(SERVER_URL + "/bonus_content_hint_msg", { 
      bonusHintMsgMin: bonusHintMsgMin,
      bonusHintMsgMax: bonusHintMsgMax
    }).then(function (res) {
      const { data } = res;
      
      if (data.status === "success") {
        messageApi.open({
          type: "success",
          content: "Bonus Content Hint messages saved successfully!"
        });
      }
      else {
        messageApi.open({
          type: "error",
          content: "Error saving Bonus Content Hint messages"
        });
      }
    });

  }

  return (
    <div>
      {contextHolder}

      {loading &&
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      }
      {!loading &&
        <>
            <h1>Bonus Content Hint Message Settings</h1>
            Send bonus hint message every
            <input min={0} type="number" placeholder="x Min" defaultValue={bonusHintMsgMin} onChange={(e) => changebonusHintMsgMin(e)} />
            -
            <input min={0} type="number" placeholder="x Max" defaultValue={bonusHintMsgMax} onChange={(e) => changebonusHintMsgMax(e)} />
            Messages
            <br />
            <button onClick={saveBonusContentHintMsg}>Save Bonus Content Hint Message Settings</button>
        </>
      }

    </div>
  )
}

export default BonusContentHintSection;
