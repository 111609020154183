import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import BarLoader from "react-spinners/BarLoader";
import axios from "../../utils/axios";

const SettingsPrice = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const formItemLayout = null;

  const columns = [
    {
      title: "Text to Speech Character to Audio Price ($ per character)",
      dataIndex: "text_to_speech_character_to_audio_price",
      key: "text_to_speech_character_to_audio_price",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "AWS E2 Servers Price ($ per month - all servers)",
      dataIndex: "aws_s2_servers_price",
      key: "aws_s2_servers_price",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "AWS E3 Servers Price ($ per month - all servers)",
      dataIndex: "aws_s3_servers_price",
      key: "aws_s3_servers_price",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Vercel Server Price ($ per month - all servers)",
      dataIndex: "vercel_price",
      key: "vercel_price",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Astria Image Flat ($ once a month)",
      dataIndex: "astria_image_flat",
      key: "astria_image_flat",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Astria Image Fee ($/per image)",
      dataIndex: "astria_image_fee",
      key: "astria_image_fee",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Diffus Image Price ($/per minute generation)",
      dataIndex: "diffus_image_price",
      key: "diffus_image_price",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Earnings Percentage Cut (integer value, 0-100)",
      dataIndex: "earnings_percentage_cut",
      key: "earnings_percentage_cut",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/settings/price").then(function (res) {
        const { data } = res;
        setData(data);
        setLoading(false);
      });
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = (isOpen) => {
    setIsModalOpen(false);
    setUploading(false);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      setUploading(true);
      await form.validateFields().then(async (values) => {
        if (
          values.earnings_percentage_cut < 0 ||
          values.earnings_percentage_cut > 100
        ) {
          messageApi.open({
            type: "error",
            content: "Earnings Percentage Cut should be between 0 and 100",
          });
          setUploading(false);
          return;
        }

        if (
          values.text_to_speech_character_to_audio_price < 0 ||
          values.aws_s2_servers_price < 0 ||
          values.aws_s3_servers_price < 0 ||
          values.vercel_price < 0 ||
          values.astria_image_flat < 0 ||
          values.astria_image_fee < 0 ||
          values.diffus_image_price < 0
        ) {
          messageApi.open({
            type: "error",
            content: "Price should be greater than or equal to 0",
          });
          setUploading(false);
          return;
        }

        const formData = new FormData();
        formData.append(
          "text_to_speech_character_to_audio_price",
          values.text_to_speech_character_to_audio_price
            ? values.text_to_speech_character_to_audio_price
            : 0
        );
        formData.append(
          "aws_s2_servers_price",
          values.aws_s2_servers_price ? values.aws_s2_servers_price : 0
        );
        formData.append(
          "aws_s3_servers_price",
          values.aws_s3_servers_price ? values.aws_s3_servers_price : 0
        );
        formData.append(
          "vercel_price",
          values.vercel_price ? values.vercel_price : 0
        );
        formData.append(
          "astria_image_flat",
          values.astria_image_flat ? values.astria_image_flat : 0
        );
        formData.append(
          "astria_image_fee",
          values.astria_image_fee ? values.astria_image_fee : 0
        );
        formData.append(
          "diffus_image_price",
          values.diffus_image_price ? values.diffus_image_price : 0
        );
        formData.append(
          "earnings_percentage_cut",
          values.earnings_percentage_cut ? values.earnings_percentage_cut : 0
        );

        const { data } = await axios.put(
          SERVER_URL + "/settings/price",
          formData
        );
        setData(data);

        messageApi.open({
          type: "success",
          content: "Price updated successfully",
        });
        closeModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setUploading(false);
    }
  };

  const editDetail = (event, record) => {
    event.stopPropagation();
    form.setFieldsValue({
      text_to_speech_character_to_audio_price:
        record.text_to_speech_character_to_audio_price,
      aws_s2_servers_price: record.aws_s2_servers_price,
      aws_s3_servers_price: record.aws_s3_servers_price,
      vercel_price: record.vercel_price,
      astria_image_flat: record.astria_image_flat,
      astria_image_fee: record.astria_image_fee,
      diffus_image_price: record.diffus_image_price,
      earnings_percentage_cut: record.earnings_percentage_cut,
    });

    openModal();
  };

  return (
    <div>
      {contextHolder}
      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataSource}
          rowKey="id"
          pagination={{
            position: ["topRight", "bottomRight"],
          }}
        />
      )}

      <Modal
        title={"Edit notification"}
        okText={uploading ? "Uploading..." : "OK"}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={closeModal}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="text_to_speech_character_to_audio_price"
            label="Text to Speech Character to Audio Price (per character)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="aws_s2_servers_price"
            label="AWS E2 Servers Price (all)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="aws_s3_servers_price"
            label="AWS E3 Servers Price (all)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="vercel_price"
            label="Vercel Server Price (all)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="astria_image_flat"
            label="Astria Image Flat (once a month)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="astria_image_fee"
            label="Astria Image Fee (per image)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="diffus_image_price"
            label="Diffus Image Price (per minute generation)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="earnings_percentage_cut"
            label="Earnings Percentage Cut (integer value)"
            rules={[{ required: true, message: "Please enter price" }]}
          >
            <Input placeholder="Please enter price" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SettingsPrice;
