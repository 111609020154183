import {
  Table,
  Button,
  Input,
  Form,
  message,
  Select,
  Space,
  Collapse,
} from "antd";
import React from "react";
import customAxios from "../../../utils/axios";
import { SERVER_URL } from "../../../Constants";

const DiffusImageGenerationSettings = (formItemLayout) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const { TextArea } = Input;

  const saveImageGenerationService = async () => {
    // Make a PUT request to save the Image Generation Service settings (/influencer/image_generation_service)
    // If the request is successful, reload the page
    // If the request is unsuccessful, show an error message

    // Get the form data
    const formData = form.getFieldsValue();

    console.log("Saving Image Generation Service");

    const { request } = await customAxios.put(
      SERVER_URL + "/influencer/image_generation_service",
      {
        ...formData,
        imageGeneration_service_id: formItemLayout.formItemLayout.id,
      }
    );
    if (request) {
      messageApi.open({
        type: "success",
        content: "Image Generation Service Saved",
      });
      //window.location.reload();
    } else {
      messageApi.open({
        type: "error",
        content: request.message,
      });
    }
  };

  const deleteImageGenerationService = async () => {
    // Make a DELETE request to delete the Image Generation Service settings (/influencer/image_generation_service)
    // If the request is successful, reload the page
    // If the request is unsuccessful, show an error message

    console.log("Deleting Image Generation Service");

    const { request } = await customAxios.delete(
      SERVER_URL +
        "/influencer/image_generation_service?imageGeneration_service_id=" +
        formItemLayout.formItemLayout.id
    );

    if (request) {
      messageApi.open({
        type: "success",
        content: "Image Generation Service Deleted",
      });
      window.location.reload();
    } else {
      messageApi.open({
        type: "error",
        content: request.message,
      });
    }
  };

  form.setFieldsValue({
    name: formItemLayout.formItemLayout.name,
    diffus_imageGeneration_sampler_name:
      formItemLayout.formItemLayout.diffus_imageGeneration_sampler_name,
    diffus_imageGeneration_cfg_scale:
      formItemLayout.formItemLayout.diffus_imageGeneration_cfg_scale,
    diffus_imageGeneration_steps:
      formItemLayout.formItemLayout.diffus_imageGeneration_steps,
    diffus_imageGeneration_restore_face:
      formItemLayout.formItemLayout.diffus_imageGeneration_restore_face,
    diffus_imageGeneration_negative_prompt:
      formItemLayout.formItemLayout.diffus_imageGeneration_negative_prompt,
    diffus_imageGeneration_clip_skip:
      formItemLayout.formItemLayout.diffus_imageGeneration_clip_skip,
    diffus_imageGeneration_model_name:
      formItemLayout.formItemLayout.diffus_imageGeneration_model_name,
    diffus_imageGeneration_n_iter:
      formItemLayout.formItemLayout.diffus_imageGeneration_n_iter,
    diffus_imageGeneration_enable_hr:
      formItemLayout.formItemLayout.diffus_imageGeneration_enable_hr,
    diffus_imageGeneration_denoising_strength:
      formItemLayout.formItemLayout.diffus_imageGeneration_denoising_strength,
    diffus_imageGeneration_hr_scale:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_scale,
    diffus_imageGeneration_hr_upscaler:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_upscaler,
    diffus_imageGeneration_hr_second_pass_steps:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_second_pass_steps,
    diffus_imageGeneration_hr_resize_x:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_resize_x,
    diffus_imageGeneration_hr_resize_y:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_resize_y,
    diffus_imageGeneration_hr_sampler_name:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_sampler_name,
    diffus_imageGeneration_hr_prompt:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_prompt,
    diffus_imageGeneration_hr_negative_prompt:
      formItemLayout.formItemLayout.diffus_imageGeneration_hr_negative_prompt,
    diffus_imageGeneration_adetailer_model_1:
      formItemLayout.formItemLayout.diffus_imageGeneration_adetailer_model_1,
    diffus_imageGeneration_adetailer_prompt_1:
      formItemLayout.formItemLayout.diffus_imageGeneration_adetailer_prompt_1,
    diffus_imageGeneration_adetailer_model_2:
      formItemLayout.formItemLayout.diffus_imageGeneration_adetailer_model_2,
    diffus_imageGeneration_adetailer_prompt_2:
      formItemLayout.formItemLayout.diffus_imageGeneration_adetailer_prompt_2,
    diffus_imageGeneration_adetailer_negative_prompt_2:
      formItemLayout.formItemLayout
        .diffus_imageGeneration_adetailer_negative_prompt_2,
    diffus_imageGeneration_adetailer_negative_prompt_1:
      formItemLayout.formItemLayout
        .diffus_imageGeneration_adetailer_negative_prompt_1,
    diffus_imageGeneration_token_classname:
      formItemLayout.formItemLayout.diffus_imageGeneration_token_classname,
    diffus_imageGeneration_prompt_prefix:
      formItemLayout.formItemLayout.diffus_imageGeneration_prompt_prefix,
    diffus_imageGeneration_prompt_suffix:
      formItemLayout.formItemLayout.diffus_imageGeneration_prompt_suffix,
    image_service_type_id: formItemLayout.formItemLayout.image_service_type_id,
    detection_confidence_1:
      formItemLayout.formItemLayout.detection_confidence_1,
    mask_dilate_erode_1: formItemLayout.formItemLayout.mask_dilate_erode_1,
    inpaint_mask_blur_1: formItemLayout.formItemLayout.inpaint_mask_blur_1,
    inpaint_denoising_strength_1:
      formItemLayout.formItemLayout.inpaint_denoising_strength_1,
    inpaint_only_masked_1: formItemLayout.formItemLayout.inpaint_only_masked_1,
    inpaint_only_masked_padding_1:
      formItemLayout.formItemLayout.inpaint_only_masked_padding_1,
    mask_k_largest_1: formItemLayout.formItemLayout.mask_k_largest_1,
    detection_confidence_2:
      formItemLayout.formItemLayout.detection_confidence_2,
    mask_dilate_erode_2: formItemLayout.formItemLayout.mask_dilate_erode_2,
    inpaint_mask_blur_2: formItemLayout.formItemLayout.inpaint_mask_blur_2,
    inpaint_denoising_strength_2:
      formItemLayout.formItemLayout.inpaint_denoising_strength_2,
    inpaint_only_masked_2: formItemLayout.formItemLayout.inpaint_only_masked_2,
    inpaint_only_masked_padding_2:
      formItemLayout.formItemLayout.inpaint_only_masked_padding_2,
    mask_k_largest_2: formItemLayout.formItemLayout.mask_k_largest_2,
    adetailer_steps_1: formItemLayout.formItemLayout.adetailer_steps_1,
    adetailer_steps_2: formItemLayout.formItemLayout.adetailer_steps_2,
    timeout: formItemLayout.formItemLayout.timeout,
    astria_inpainting_active:
      formItemLayout.formItemLayout.astria_inpainting_active,
    astria_face_correct_active:
      formItemLayout.formItemLayout.astria_face_correct_active,
    astria_super_resolution_active:
      formItemLayout.formItemLayout.astria_super_resolution_active,
    astria_face_swap_active:
      formItemLayout.formItemLayout.astria_face_swap_active,
    astria_backend_version:
      formItemLayout.formItemLayout.astria_backend_version,
    seaart_vae: formItemLayout.formItemLayout.seaart_vae,
    seaart_model_weight: formItemLayout.formItemLayout.seaart_model_weight,
    imageGenerationResolution:
      formItemLayout.formItemLayout.imageGenerationResolution,
    film_grain_active: formItemLayout.formItemLayout.film_grain_active,
    astria_inpainting_active:
      formItemLayout.formItemLayout.astria_inpainting_active,
    scheduler: formItemLayout.formItemLayout.scheduler,
    astria_model_id: formItemLayout.formItemLayout.astria_model_id,
    seaart_category: formItemLayout.formItemLayout.seaart_category,
    seaart_art_model_no: formItemLayout.formItemLayout.seaart_art_model_no,
    popup_prefixes: formItemLayout.formItemLayout.popup_prefixes,
    image_generation_prompt_sfw_placeholder:
      formItemLayout.formItemLayout.image_generation_prompt_sfw_placeholder,
    help_message: formItemLayout.formItemLayout.help_message,
    allowed_image_amounts: formItemLayout.formItemLayout.allowed_image_amounts,
    interpreation_prefix: formItemLayout.formItemLayout.interpreation_prefix,
    enforce_presence_1: formItemLayout.formItemLayout.enforce_presence_1,
    enforce_presence_2: formItemLayout.formItemLayout.enforce_presence_2,
    interpration_prompt_placeholder:
      formItemLayout.formItemLayout.interpration_prompt_placeholder,
    nsfw: formItemLayout.formItemLayout.nsfw,
    ad_bounding_box_padding_1:
      formItemLayout.formItemLayout.ad_bounding_box_padding_1,
    ad_bounding_box_padding_2:
      formItemLayout.formItemLayout.ad_bounding_box_padding_2,
    ad_checkpoint_1: formItemLayout.formItemLayout.ad_checkpoint_1,
    ad_checkpoint_2: formItemLayout.formItemLayout.ad_checkpoint_2,
    use_interpreation_engine:
      formItemLayout.formItemLayout.use_interpreation_engine,
      visible: formItemLayout.formItemLayout.visible,
  });

  const parseParameters = () => {
    /*
Example Input:



parameters

(score_9, score_8_up, score_7_up:1.1), score_7_up,

a realistic photo of a 20-year-old Latina. shoulder-length brunette hair with a few loose strands framing her face, hazel eyes that sparkle with curiosity and focus. 5’3 tall with a curvaceous figure,

at a music concert with friends, smiling, happy,


realistic, photographic, highly detailed,

<lora:wreal_consolidated:0.5>
Negative prompt: score_6, score_5, score_4, low quality, bad quality, monochrome,text, labels, cartoon, drawing
EZNegPONYXL-neg
Steps: 20, Sampler: DPM++ SDE Karras, CFG scale: 6, Seed: 3352811367, Size: 640x1024, Model hash: 9f2cfd8b42, Model: fastPhotoPony_v80MixB, Denoising strength: 0.36, ADetailer model: face_yolov8n.pt, ADetailer prompt: " (score_9, score_8_up, score_7_up:1.1), score_7_up, \na 20-year-old Latina. shoulder-length brunette hair with a few loose strands framing her face, hazel eyes that sparkle,smiling, happy, \nbeautiful woman, closeup, portrait, looking at viewer, \nrealistic, photographic, highly detailed, photo realistic,\n<lora:wreal_consolidated:0.3> \n\n", ADetailer negative prompt: "score_6, score_5, score_4, low quality, bad quality, monochrome,text, labels, cartoon, drawing, \n EZNegPONYXL-neg", ADetailer confidence: 0.3, ADetailer mask only top k largest: 1, ADetailer dilate erode: 20, ADetailer mask blur: 8, ADetailer denoising strength: 0.4, ADetailer inpaint only masked: True, ADetailer inpaint padding: 200, ADetailer version: 24.6.0, Hires upscale: 2, Hires steps: 10, Hires upscaler: 4x_foolhardy_Remacri, TI hashes: "EZNegPONYXL-neg: 4dbf83ac7cbd, EZNegPONYXL-neg: 4dbf83ac7cbd", Version: f0.0.9-latest-51-g5bea443d


OR


parameters
<lora:Colby_Melvin_3:1.1>,colbymelvinfull,
 man, muscular,30 something male, solo, oval shaped head, soft face, soft facial features, gentle face, blue eyes:0.3,
 (naked, large cock, erect penis), full body,
 (laying on a bed, stroking his erect cock, masturbating),
 high resolution, natural skin color, high detail,
 <lora:Colby_Melvin_s2:0.4>
 <lora:Film Grain style:0.1>
 <lora:photorealistic_portrait_notrigger:0.3>
 <lora:xpenis-v2.13:1> penis
 Negative prompt: low resolution, bad quality, text, watermark, woman, female,
 DeepNegative_xl_v1
 Steps: 28, Sampler: DPM++ SDE Karras, CFG scale: 5, Seed: 1348481788, Size: 680x1024, Model hash: 059934ff58, Model: ponyRealism_v21MainVAE, Denoising strength: 0.27, ADetailer model: face_yolov8n.pt, ADetailer prompt: " <lora:Colby_Melvin_3:0.7>, colbymelvinfull,\n\nhigh resolution, natural skin color, high detail,\n <lora:Colby_Melvin_s2:0.5>\n<lora:Film Grain style:0.1>\n <lora:photorealistic_portrait_notrigger:0.3> ", ADetailer negative prompt: "low resolution, bad quality, text, watermark, woman, female, \nDeepNegative_xl_v1", ADetailer confidence: 0.6, ADetailer mask only top k largest: 1, ADetailer dilate erode: 20, ADetailer mask blur: 5, ADetailer denoising strength: 0.5, ADetailer inpaint only masked: True, ADetailer inpaint padding: 32, ADetailer model 2nd: penisV2.pt, ADetailer prompt 2nd: " <lora:xpenis-v2.13:1> penis", ADetailer negative prompt 2nd: "low resolution, bad quality, text, watermark, woman, female,\nDeepNegative_xl_v1", ADetailer confidence 2nd: 0.3, ADetailer dilate erode 2nd: 4, ADetailer mask blur 2nd: 4, ADetailer denoising strength 2nd: 0.4, ADetailer inpaint only masked 2nd: True, ADetailer inpaint padding 2nd: 32, ADetailer version: 24.6.0, Hires upscale: 2, Hires steps: 18, Hires upscaler: 4x_foolhardy_Remacri, TI hashes: "DeepNegative_xl_v1: eabfb312e2df, DeepNegative_xl_v1: eabfb312e2df, DeepNegative_xl_v1: eabfb312e2df, DeepNegative_xl_v1: eabfb312e2df", Version: f0.0.9-latest-51-g5bea443d

*/

    let parameters = form.getFieldValue("paramaterInput");
    parameters = parameters.trim();

    // Remove "paramaters" from the start of the string
    parameters = parameters.replace("parameters", "");

    parameters = parameters.replace("Parse Text", "")

    // Get the positive prompt by splitting at "Negative prompt:"
    let positivePrompt = parameters.split("Negative prompt:")[0];
    positivePrompt = positivePrompt.trim();

    // Get the negative prompt by splitting at "Steps:"
    let negativePrompt = parameters.split("Negative prompt:")[1];
    negativePrompt = negativePrompt.split("Steps:")[0].trim();

    // Get the rest of the parameters
    let rest = parameters.split("Negative prompt:")[1];
    rest = "{ Steps: " + rest.split("Steps:")[1] + "}";

    // remove "TI hashes" and everything after it
    try {
      rest = rest.split("TI hashes:")[0];

    }
    catch (e) {
      console.log("No TI hashes found");
    }

    // remove "Lora hashes" and everything after it
    try {
      rest = rest.split("Lora hashes:")[0];
    }
    catch (e) {
      console.log("No Lora hashes found");
    }

    // Parse as json

    // Add "" to all keys
    rest = rest.replace(/([a-zA-Z0-9.\_ ]+): /g, '"$1": ');

    // Add "" to all values
    rest = rest.replace(/": ([a-zA-Z0-9+.\_\- ]+),/g, '": "$1",');

    // Remove " Version": and everything after it
    try {
      rest = rest.split('" Version":')[0];
    }
    catch (e) {
      console.log("No Version found");
    }

    rest = rest.trim();

    // Check if last character is a comma
    if (rest.slice(-1) == ",") {
      // Remove last comma
      rest = rest.slice(0, -1);

      rest = rest + "}";
    }

    let paramertersObject = JSON.parse(rest);

    // Get the current form values
    let steps = form.getFieldValue("diffus_imageGeneration_steps");
    let sampler = form.getFieldValue("diffus_imageGeneration_sampler_name");
    let cfgScale = form.getFieldValue("diffus_imageGeneration_cfg_scale");
    let size = form.getFieldValue("imageGenerationResolution");
    let model = form.getFieldValue("diffus_imageGeneration_model_name");
    let denoisingStrength = form.getFieldValue("diffus_imageGeneration_denoising_strength");
    let clipSkip = form.getFieldValue("diffus_imageGeneration_clip_skip");

    let adetailerModel1 = form.getFieldValue("diffus_imageGeneration_adetailer_model_1");
    let adetailerPrompt1 = form.getFieldValue("diffus_imageGeneration_adetailer_prompt_1");
    let adetailerNegativePrompt1 = form.getFieldValue("diffus_imageGeneration_adetailer_negative_prompt_1");
    let adetailerConfidence1 = form.getFieldValue("detection_confidence_1");
    let adetailerMaskOnlyTopKLargest1 = form.getFieldValue("mask_k_largest_1");
    let adetailerDilateErode1 = form.getFieldValue("mask_dilate_erode_1");
    let adetailerMaskBlur1 = form.getFieldValue("inpaint_mask_blur_1");
    let adetailerDenoisingStrength1 = form.getFieldValue("inpaint_denoising_strength_1");
    let adetailerInpaintOnlyMasked1 = form.getFieldValue("inpaint_only_masked_1");
    let adetailerInpaintPadding1 = form.getFieldValue("ad_bounding_box_padding_1");

    let adetailerModel2 = form.getFieldValue("diffus_imageGeneration_adetailer_model_2");
    let adetailerPrompt2 = form.getFieldValue("diffus_imageGeneration_adetailer_prompt_2");
    let adetailerNegativePrompt2 = form.getFieldValue("diffus_imageGeneration_adetailer_negative_prompt_2");
    let adetailerConfidence2 = form.getFieldValue("detection_confidence_2");
    let adetailerMaskOnlyTopKLargest2 = form.getFieldValue("mask_k_largest_2");
    let adetailerDilateErode2 = form.getFieldValue("mask_dilate_erode_2");
    let adetailerMaskBlur2 = form.getFieldValue("inpaint_mask_blur_2");
    let adetailerDenoisingStrength2 = form.getFieldValue("inpaint_denoising_strength_2");
    let adetailerInpaintOnlyMasked2 = form.getFieldValue("inpaint_only_masked_2");
    let adetailerInpaintPadding2 = form.getFieldValue("ad_bounding_box_padding_2");

    let hiresUpscale = form.getFieldValue("diffus_imageGeneration_hr_scale");
    let hiresSteps = form.getFieldValue("diffus_imageGeneration_hr_second_pass_steps");
    let hiresUpscaler = form.getFieldValue("diffus_imageGeneration_hr_upscaler");

    let unkownParameters = "Unkown Paramaters: \n";

    console.log("adetailerModel2:" + adetailerModel2);

    // Loop through all keys and set the values
    Object.keys(paramertersObject).forEach(function (parameter) {
      let value = paramertersObject[parameter];

      parameter = parameter.trim();

      console.log(parameter);
      if (parameter == "Steps") {
        steps = value;
      } else if (parameter == "Sampler") {
        sampler = value;
      } else if (parameter == "Clip Skip") {
        clipSkip = value;
      }
      else if (parameter == "CFG scale") {
        cfgScale = value;
      }
      else if (parameter == "Size") {
        size = value;
      }
      else if (parameter == "Model") {
        model = value;
      }
      else if (parameter == "Denoising strength") {
        denoisingStrength = value;
      }
      else if (parameter == "Clip skip") {
        clipSkip = value;
      }
      else if (parameter == "ADetailer model") {
        adetailerModel1 = value;
      }
      else if (parameter == "ADetailer prompt") {
        adetailerPrompt1 = value;
      }
      else if (parameter == "ADetailer negative prompt") {
        adetailerNegativePrompt1 = value;
      }
      else if (parameter == "ADetailer confidence") {
        adetailerConfidence1 = value;
      }
      else if (parameter == "ADetailer mask only top k largest") {
        adetailerMaskOnlyTopKLargest1 = value;
      }
      else if (parameter == "ADetailer dilate erode") {
        adetailerDilateErode1 = value;
      }
      else if (parameter == "ADetailer mask blur") {
        adetailerMaskBlur1 = value;
      }
      else if (parameter == "ADetailer denoising strength") {
        adetailerDenoisingStrength1 = value;
      }
      else if (parameter == "ADetailer inpaint only masked") {
        adetailerInpaintOnlyMasked1 = value;
      }
      else if (parameter == "ADetailer inpaint padding") {
        adetailerInpaintPadding1 = value;
      }      
      else if (parameter == "ADetailer model 2nd") {
        adetailerModel2 = value;
      }
      else if (parameter == "ADetailer prompt 2nd") {
        adetailerPrompt2 = value.trim();
      }
      else if (parameter == "ADetailer negative prompt 2nd") {
        adetailerNegativePrompt2 = value.trim();
      }
      else if (parameter == "ADetailer confidence 2nd") {
        adetailerConfidence2 = value;
      }
      else if (parameter == "ADetailer mask only top k largest 2nd") {
        adetailerMaskOnlyTopKLargest2 = value;
      }
      else if (parameter == "ADetailer dilate erode 2nd") {
        adetailerDilateErode2 = value;
      }
      else if (parameter == "ADetailer mask blur 2nd") {
        adetailerMaskBlur2 = value;
      }
      else if (parameter == "ADetailer denoising strength 2nd") {
        adetailerDenoisingStrength2 = value;
      }
      else if (parameter == "ADetailer inpaint only masked 2nd") {
        adetailerInpaintOnlyMasked2 = value;
      }
      else if (parameter == "ADetailer inpaint padding 2nd") {
        adetailerInpaintPadding2 = value;
      }
      else if (parameter == "Hires upscale") {
        hiresUpscale = value;
      }
      else if (parameter == "Hires steps") {
        hiresSteps = value;
      }
      else if (parameter == "Hires upscaler") {
        hiresUpscaler = value;
      } else{
        unkownParameters += parameter + ": " + value + "\n";
      }
    });

      form.setFieldsValue({
        paramaterInput: unkownParameters,

        diffus_imageGeneration_model_name: model,

        diffus_imageGeneration_prompt_prefix: positivePrompt,

        diffus_imageGeneration_steps: Number(steps),
        diffus_imageGeneration_sampler_name: sampler,
        diffus_imageGeneration_cfg_scale: cfgScale,
        imageGenerationResolution: size,
        diffus_imageGeneration_denoising_strength: denoisingStrength,

        diffus_imageGeneration_clip_skip: clipSkip,

        diffus_imageGeneration_negative_prompt: negativePrompt,

        diffus_imageGeneration_adetailer_model_1: adetailerModel1,
        diffus_imageGeneration_adetailer_model_2: adetailerModel2,
        diffus_imageGeneration_adetailer_prompt_1: adetailerPrompt1,
        diffus_imageGeneration_adetailer_prompt_2: adetailerPrompt2,
        diffus_imageGeneration_adetailer_negative_prompt_1: adetailerNegativePrompt1,
        diffus_imageGeneration_adetailer_negative_prompt_2: adetailerNegativePrompt2,
        detection_confidence_1: adetailerConfidence1,
        detection_confidence_2: adetailerConfidence2,
        mask_k_largest_1: adetailerMaskOnlyTopKLargest1,
        mask_k_largest_2: adetailerMaskOnlyTopKLargest2,
        mask_dilate_erode_1: adetailerDilateErode1,
        mask_dilate_erode_2: adetailerDilateErode2,
        inpaint_mask_blur_1: adetailerMaskBlur1,
        inpaint_mask_blur_2: adetailerMaskBlur2,
        inpaint_denoising_strength_1: adetailerDenoisingStrength1,
        inpaint_denoising_strength_2: adetailerDenoisingStrength2,
        inpaint_only_masked_1: adetailerInpaintOnlyMasked1 == "True",
        inpaint_only_masked_2: adetailerInpaintOnlyMasked2 == "True",
        ad_bounding_box_padding_1: adetailerInpaintPadding1,
        ad_bounding_box_padding_2: adetailerInpaintPadding2,

        diffus_imageGeneration_hr_second_pass_steps: hiresSteps,

        ad_checkpoint_1: adetailerModel1,
        ad_checkpoint_2: adetailerModel2,

        diffus_imageGeneration_hr_upscaler: hiresUpscaler,
        diffus_imageGeneration_hr_scale: hiresUpscale,
        diffus_imageGeneration_hr_second_pass_steps: hiresSteps,
      })
  
  };


  return (
    <>
      {contextHolder}
      <Form form={form} name="dynamic_rule" layout="vertical">
        <h2>
          {formItemLayout.formItemLayout.name +
            " Image Service Generation Settings:"}
        </h2>
        <Form.Item
          {...formItemLayout}
          name="name"
          label="Image Generation Service name"
        >
          <Input
            id="name"
            type="text"
            min={1}
            placeholder="Please input the Image Service Name"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="image_service_type_id"
          label="Image Generation Service Endpoint Type"
        >
          <select
            name="image_service_type_id"
            id="image_service_type_id"
            style={{ width: "100%" }}
          >
            <option value="1">Astria</option>
            <option value="2">Sea Art</option>
            <option value="3">Diffus</option>
            <option value="4">RandomSeed</option>
            <option value="5">Runpod Serverless</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="visible"
          label="Visible"
        >
          <select
            name="visible"
            id="visible"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="paramaterInput"
          label="Automatic Paramater Parser"
          id = "paramaterInput"
        >
          <TextArea rows={8} />
        </Form.Item>
        <Button onClick={parseParameters}>Parse Paramaters</Button>

        <Form.Item
          {...formItemLayout}
          name="nsfw"
          label="NSFW (Changes how much the user gets charged)"
        >
          <select name="nsfw" id="nsfw" style={{ width: "100%" }}>
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item {...formItemLayout} name="timeout" label="Timeout">
          <Input
            id="timeout"
            type="number"
            min={1}
            placeholder="Please input the Timeout"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="popup_prefixes"
          label='Popup Prefixes (e.g: "%influencer% standing", "%influencer% sitting", "%influencer% lying")'
        >
          <Input
            id="popup_prefixes"
            type="text"
            min={1}
            placeholder="Please input the Popup Prefixes"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="use_interpreation_engine"
          label="Use Interpreation Engine (If true, the interpreation engine will be used to generate the prompt)"
        >
          <select
            name="use_interpreation_engine"
            id="use_interpreation_engine"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="image_generation_prompt_sfw_placeholder"
          label="Image Generation Prompt Examples"
        >
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item {...formItemLayout} name="help_message" label="Help Message">
          <Input
            id="help_message"
            type="text"
            min={0}
            placeholder="Please input the Help Message"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="interpreation_prefix"
          label="Interpreation Prefix (Gets appended in front of a interpretation result / in front of the interpretation prompt placeholder if no interpreation was found)"
        >
          <Input
            id="interpreation_prefix"
            type="text"
            min={1}
            placeholder="Please input the Interpreation Prefix"
          />
        </Form.Item>
        
        <Form.Item
          {...formItemLayout}
          name="interpration_prompt_placeholder"
          label="Interpration Prompt Placeholder (Text that will be appended to the prompt if no interpreation was found)"
        >
          <Input
            id="interpration_prompt_placeholder"
            type="text"
            min={1}
            placeholder="Please input the Interpration Prompt Placeholder"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="allowed_image_amounts"
          label="Allowed Image Amounts"
        >
          <Input
            id="allowed_image_amounts"
            type="text"
            min={0}
            placeholder="Please input the Allowed Image Amounts"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="imageGenerationResolution"
          label="Image Generation Resolution [WidthxHeight] (w and h must be in multiples of 8)"
        >
          <Input
            id="imageGenerationResolution"
            type="text"
            min={1}
            placeholder="Please input Image Generation Resolution in [WidthxHeight] (w and h must be in multiples of 8)"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_sampler_name"
          label="Image Generation Sampler Name"
        >
          <Input
            id="diffus_imageGeneration_sampler_name"
            type="text"
            min={1}
            placeholder="Please input Image Generation Sampler Name"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_cfg_scale"
          label="Image Generation Cfg Scale"
        >
          <Input
            id="diffus_imageGeneration_cfg_scale"
            type="number"
            min={1}
            placeholder="Please input Image Generation Cfg Scale"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_steps"
          label="Image Generation Steps"
        >
          <Input
            id="diffus_imageGeneration_steps"
            type="number"
            min={1}
            placeholder="Please input Image Generation Steps"
          />
        </Form.Item>
        {/*<Form.Item
        {...formItemLayout}
        name="diffus_imageGeneration_restore_face"
        label="Image Generation Restore Face (Not recommended for use with Loras)"
      >
        <select name="diffus_imageGeneration_restore_face" id="diffus_imageGeneration_restore_face"
                style={{width: "100%"}}>
          <option value="1">True</option>
          <option value="0">False</option>
        </select>
      </Form.Item>*/}
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_negative_prompt"
          label="Image Generation Negative Prompt"
        >
          {/*<Input
            id="diffus_imageGeneration_negative_prompt"
            type="text"
            min={1}
            placeholder="Please input Image Generation Negative Prompt"
          />*/}
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_token_classname"
          label="Image Generation Token Class"
        >
          <Input
            id="diffus_imageGeneration_token_classname"
            type="text"
            min={1}
            placeholder="Please input Image Generation Token Class"
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_enable_hr"
          label="Image Generation Enable HR (High res fix)"
        >
          <select
            name="diffus_imageGeneration_enable_hr"
            id="diffus_imageGeneration_enable_hr"
            style={{ width: "100%" }}
          >
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </Form.Item>
        {(formItemLayout.formItemLayout.image_service_type_id == 3 ||
          formItemLayout.formItemLayout.image_service_type_id == 4 ||
          formItemLayout.formItemLayout.image_service_type_id == 5) && (
          <>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_clip_skip"
              label="Image Generation Clip Skip"
            >
              <Input
                id="diffus_imageGeneration_clip_skip"
                type="number"
                min={1}
                placeholder="Please input Image Generation Clip Skip"
              />
            </Form.Item>
            {/*<Form.Item
        {...formItemLayout}
        name="diffus_imageGeneration_n_iter"
        label="Image Generation N Iter (Batch count)"
      >
        <Input id="diffus_imageGeneration_n_iter" type="number" min={1} placeholder="Please input Image Generation N Iter"/>
      </Form.Item>*/}
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_denoising_strength"
              label="Image Generation Denoising Strength"
            >
              <Input
                id="diffus_imageGeneration_denoising_strength"
                type="number"
                min={0}
                placeholder="Please input Image Generation Denoising Strength"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_hr_scale"
              label="Image Generation HR Scale"
            >
              <Input
                id="diffus_imageGeneration_hr_scale"
                type="number"
                min={1}
                placeholder="Please input Image Generation HR Scale"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_hr_upscaler"
              label="Image Generation HR Upscaler"
            >
              <Input
                id="diffus_imageGeneration_hr_upscaler"
                type="text"
                min={1}
                placeholder="Please input Image Generation HR Upscaler"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_hr_second_pass_steps"
              label="Image Generation HR Second Pass Steps"
            >
              <Input
                id="diffus_imageGeneration_hr_second_pass_steps"
                type="number"
                min={0}
                placeholder="Please input Image Generation HR Second Pass Steps"
              />
            </Form.Item>
            {/*<Form.Item
        {...formItemLayout}
        name="diffus_imageGeneration_hr_resize_x"
        label="Image Generation HR Resize X"
      >
        <Input id="diffus_imageGeneration_hr_resize_x" type="number" min={0} placeholder="Please input Image Generation HR Resize X"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="diffus_imageGeneration_hr_resize_y"
        label="Image Generation HR Resize Y"
      >
        <Input id="diffus_imageGeneration_hr_resize_y" type="number" min={0} placeholder="Please input Image Generation HR Resize Y"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="diffus_imageGeneration_hr_prompt"
        label="Image Generation HR Prompt"
      >
        <Input id="diffus_imageGeneration_hr_prompt" type="text" min={1} placeholder="Please input Image Generation HR Prompt"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="diffus_imageGeneration_hr_negative_prompt"
        label="Image Generation HR Negative Prompt"
      >
        <Input id="diffus_imageGeneration_hr_negative_prompt" type="text" min={1} placeholder="Please input Image Generation HR Negative Prompt"/>
      </Form.Item>*/}
          </>
        )}
        {(formItemLayout.formItemLayout.image_service_type_id == 2 ||
          formItemLayout.formItemLayout.image_service_type_id == 3 ||
          formItemLayout.formItemLayout.image_service_type_id == 5) && (
          <>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_model_name"
              label="Image Generation Model Name [sdXL_v10VAEFix.safetensors, crystalClearXL_ccxl.safetensors]"
            >
              <Input
                id="diffus_imageGeneration_model_name"
                type="text"
                min={1}
                placeholder="Please input Image Generation Model Name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_hr_sampler_name"
              label="Image Generation HR Sampler Name"
            >
              <Input
                id="diffus_imageGeneration_hr_sampler_name"
                type="text"
                min={1}
                placeholder="Please input Image Generation HR Sampler Name"
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_prompt_prefix"
          label="Image Generation Prompt Prefix"
        >
          {/*<Input
            id="diffus_imageGeneration_prompt_prefix"
            type="text"
            min={1}
            placeholder="Please input Image Generation Prompt Prefix"
          />*/}
          <TextArea rows={3} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="diffus_imageGeneration_prompt_suffix"
          label="Image Generation Prompt Suffix"
        >
          {/*<Input
            id="diffus_imageGeneration_prompt_suffix"
            type="text"
            min={1}
            placeholder="Please input Image Generation Prompt Suffix"
          />*/}
          <TextArea rows={3} />
        </Form.Item>
        {(formItemLayout.formItemLayout.image_service_type_id == 1 ||
          formItemLayout.formItemLayout.image_service_type_id == 2) && (
          <>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_model_name"
              label="Image Generation Lora Model Name [e.g <lora:955077:0.9>] Gets put at beginning of prompt"
            >
              <Input
                id="diffus_imageGeneration_model_name"
                type="text"
                min={1}
                placeholder="Please input Image Generation Model Name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="astria_super_resolution_active"
              label="Super Resolution Active"
            >
              <select
                name="astria_super_resolution_active"
                id="astria_super_resolution_active"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="astria_inpainting_active"
              label="Inpainting Active"
            >
              <select
                name="astria_inpainting_active"
                id="astria_inpainting_active"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="film_grain_active"
              label="Film Grain Active"
            >
              <select
                name="film_grain_active"
                id="film_grain_active"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item {...formItemLayout} name="scheduler" label="Scheduler">
              <Input
                id="scheduler"
                type="text"
                min={1}
                placeholder="Please input Scheduler"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="astria_model_id"
              label="Model ID"
            >
              <Input
                id="astria_model_id"
                type="text"
                min={1}
                placeholder="Please input Model ID"
              />
            </Form.Item>
          </>
        )}
        {formItemLayout.formItemLayout.image_service_type_id === 1 && (
          <>
            <Form.Item
              {...formItemLayout}
              name="astria_face_correct_active"
              label="Astria Face Correct Active"
            >
              <select
                name="astria_face_correct_active"
                id="astria_face_correct_active"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="astria_face_swap_active"
              label="Astria Face Swap Active"
            >
              <select
                name="astria_face_swap_active"
                id="astria_face_swap_active"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="astria_backend_version"
              label="Astria Backend Version"
            >
              <Input
                id="astria_backend_version"
                type="text"
                min={1}
                placeholder="Please input Astria Backend Version"
              />
            </Form.Item>
          </>
        )}
        {formItemLayout.formItemLayout.image_service_type_id === 2 && (
          <>
            <Form.Item
              {...formItemLayout}
              name="seaart_vae"
              label="Sea Art VAE"
            >
              <Input
                id="seaart_vae"
                type="text"
                min={1}
                placeholder="Please input Sea Art VAE"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="seaart_model_weight"
              label="Sea Art Model Weight"
            >
              <Input
                id="seaart_model_weight"
                type="text"
                min={1}
                placeholder="Please input Sea Art Model Weight"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="seaart_category"
              label="Sea Art Category"
            >
              <Input
                id="seaart_category"
                type="text"
                min={1}
                placeholder="Please input Sea Art Category"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="seaart_art_model_no"
              label="Sea Art Art Model No"
            >
              <Input
                id="seaart_art_model_no"
                type="text"
                min={1}
                placeholder="Please input Sea Art Art Model No"
              />
            </Form.Item>
          </>
        )}
        {(formItemLayout.formItemLayout.image_service_type_id == 3 ||
          formItemLayout.formItemLayout.image_service_type_id == 4 ||
          formItemLayout.formItemLayout.image_service_type_id == 5) && (
          <>
            <h3>Adetailer 1</h3>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_adetailer_model_1"
              label="Image Generation Adetailer Model 1"
            >
              <Input
                id="diffus_imageGeneration_adetailer_model_1"
                type="text"
                min={1}
                placeholder="Please input Image Generation Adetailer Model 1"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_adetailer_prompt_1"
              label="Image Generation Adetailer Prompt 1"
            >
              {/*<Input
                id="diffus_imageGeneration_adetailer_prompt_1"
                type="text"
                min={1}
                placeholder="Please input Image Generation Adetailer Prompt 1"
              />*/}
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_adetailer_negative_prompt_1"
              label="Image Generation Adetailer Negative Prompt 1"
            >
              {/*<Input
                id="diffus_imageGeneration_adetailer_negative_prompt_1"
                type="text"
                min={1}
                placeholder="Please input Image Generation Adetailer Negative Prompt 1"
              />*/}
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="detection_confidence_1"
              label="Detection Confidence"
            >
              <Input
                id="detection_confidence_1"
                type="number"
                min={0}
                placeholder="Please input Detection Confidence"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="mask_dilate_erode_1"
              label="Mask Dilate Erode"
            >
              <Input
                id="mask_dilate_erode_1"
                type="number"
                min={0}
                placeholder="Please input Mask Dilate Erode"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_mask_blur_1"
              label="Inpaint Mask Blur"
            >
              <Input
                id="inpaint_mask_blur_1"
                type="number"
                min={0}
                placeholder="Please input Inpaint Mask Blur"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_denoising_strength_1"
              label="Inpaint Denoising Strength"
            >
              <Input
                id="inpaint_denoising_strength_1"
                type="number"
                min={0}
                placeholder="Please input Inpaint Denoising Strength"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_only_masked_1"
              label="Inpaint Only Masked"
            >
              <select
                name="inpaint_only_masked_1"
                id="inpaint_only_masked_1"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_only_masked_padding_1"
              label="Inpaint Only Masked Padding"
            >
              <Input
                id="inpaint_only_masked_padding_1"
                type="number"
                min={0}
                placeholder="Please input Inpaint Only Masked Padding"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="mask_k_largest_1"
              label="Mask K Largest"
            >
              <Input
                id="mask_k_largest_1"
                type="number"
                min={0}
                placeholder="Please input Mask K Largest"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="adetailer_steps_1"
              label="Adetailer Steps 1"
            >
              <Input
                id="adetailer_steps_1"
                type="number"
                min={0}
                placeholder="Please input Adetailer Steps 1"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="ad_bounding_box_padding_1"
              label="Ad Bounding Box Padding 1"
            >
              <Input
                id="ad_bounding_box_padding_1"
                type="number"
                min={0}
                placeholder="Please input Ad Bounding Box Padding 1"
              />
            </Form.Item>
            {formItemLayout.formItemLayout.image_service_type_id == 5 && (
              <Form.Item
                {...formItemLayout}
                name="ad_checkpoint_1"
                label="Adetailer Checkpoint 1"
              >
                <Input
                  id="ad_checkpoint_1"
                  type="text"
                  min={1}
                  placeholder="Please input Adetailer Checkpoint 1"
                />
              </Form.Item>
            )}
            <Form.Item
              {...formItemLayout}
              name="enforce_presence_1"
              label="Enforce Presence 1"
            >
              <select
                name="enforce_presence_1"
                id="enforce_presence_1"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <h3>Adetailer 2</h3>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_adetailer_model_2"
              label="Image Generation Adetailer Model 2"
            >
              <Input
                id="diffus_imageGeneration_adetailer_model_2"
                type="text"
                min={1}
                placeholder="Please input Image Generation Adetailer Model 2"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_adetailer_prompt_2"
              label="Image Generation Adetailer Prompt 2"
            >
              {/*<Input
                id="diffus_imageGeneration_adetailer_prompt_2"
                type="text"
                min={1}
                placeholder="Please input Image Generation Adetailer Prompt 2"
              />*/}
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="diffus_imageGeneration_adetailer_negative_prompt_2"
              label="Image Generation Adetailer Negative Prompt 2"
            >
              {/*<Input
                id="diffus_imageGeneration_adetailer_negative_prompt_2"
                type="text"
                min={1}
                placeholder="Please input Image Generation Adetailer Negative Prompt 2"
              />*/}
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="detection_confidence_2"
              label="Detection Confidence"
            >
              <Input
                id="detection_confidence_2"
                type="number"
                min={0}
                placeholder="Please input Detection Confidence"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="mask_dilate_erode_2"
              label="Mask Dilate Erode"
            >
              <Input
                id="mask_dilate_erode_2"
                type="number"
                min={0}
                placeholder="Please input Mask Dilate Erode"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_mask_blur_2"
              label="Inpaint Mask Blur"
            >
              <Input
                id="inpaint_mask_blur_2"
                type="number"
                min={0}
                placeholder="Please input Inpaint Mask Blur"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_denoising_strength_2"
              label="Inpaint Denoising Strength"
            >
              <Input
                id="inpaint_denoising_strength_2"
                type="number"
                min={0}
                placeholder="Please input Inpaint Denoising Strength"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_only_masked_2"
              label="Inpaint Only Masked"
            >
              <select
                name="inpaint_only_masked_2"
                id="inpaint_only_masked_2"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="inpaint_only_masked_padding_2"
              label="Inpaint Only Masked Padding"
            >
              <Input
                id="inpaint_only_masked_padding_2"
                type="number"
                min={0}
                placeholder="Please input Inpaint Only Masked Padding"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="mask_k_largest_2"
              label="Mask K Largest"
            >
              <Input
                id="mask_k_largest_2"
                type="number"
                min={0}
                placeholder="Please input Mask K Largest"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="adetailer_steps_2"
              label="Adetailer Steps 2"
            >
              <Input
                id="adetailer_steps_2"
                type="number"
                min={0}
                placeholder="Please input Adetailer Steps 2"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="ad_bounding_box_padding_2"
              label="Ad Bounding Box Padding 2"
            >
              <Input
                id="ad_bounding_box_padding_2"
                type="number"
                min={0}
                placeholder="Please input Ad Bounding Box Padding 2"
              />
            </Form.Item>
            {formItemLayout.formItemLayout.image_service_type_id == 5 && (
              <Form.Item
                {...formItemLayout}
                name="ad_checkpoint_2"
                label="Adetailer Checkpoint 2"
              >
                <Input
                  id="ad_checkpoint_2"
                  type="text"
                  min={1}
                  placeholder="Please input Adetailer Checkpoint 2"
                />
              </Form.Item>
            )}
            <Form.Item
              {...formItemLayout}
              name="enforce_presence_2"
              label="Enforce Presence 2"
            >
              <select
                name="enforce_presence_2"
                id="enforce_presence_2"
                style={{ width: "100%" }}
              >
                <option value="1">True</option>
                <option value="0">False</option>
              </select>
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button type="primary" onClick={() => saveImageGenerationService()}>
            Save Service
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            danger
            onClick={() => deleteImageGenerationService()}
          >
            Delete Service
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DiffusImageGenerationSettings;
