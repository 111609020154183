import {
  Button,
  Form,
  Image,
  Input,
  message,
  Modal,
  Select,
  Table,
  Upload,
} from "antd";
import BarLoader from "react-spinners/BarLoader";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import axios from "../../utils/axios";
import FreeMessagesSection from "./FreeMessagesSection";
import BonusContentHintSection from "./BonusContentHintSection";
import DemoMessagesSection from "./DemoMessagesSection";

const normFile = (e) => {
  // console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const InfluencerNotifications = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setData] = useState([]);
  const [influencerOptions, setInfluencerOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [fileUploading, setFileUploading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const formItemLayout = null;

  const columns = [
    {
      title: "Influencer",
      dataIndex: "influencer_name",
      key: "influencer_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Notification",
      dataIndex: "notification_id",
      key: "notification_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (url) =>
        url ? <Image width={100} src={url} /> : <div>No image</div>,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id);
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      customAxios
        .get(SERVER_URL + "/influencer/notifications")
        .then(function (res) {
          const { notifications, influencers } = res.data.data;
          setData(notifications);
          setInfluencerOptions(
            influencers.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })
          );
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = (isOpen) => {
    setIsModalOpen(false);
    setFileUploading(false);
    setImageList([]);
    form.resetFields();
  };
  const handleSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        if (values["influencer_id"] === undefined) {
          messageApi.error("Please select influencer");
          return;
        }
        if (values["notification_id"] === undefined) {
          messageApi.error("Please select notification");
          return;
        }
        if (values["message"] === undefined) {
          messageApi.error("Please enter message");
          return;
        }
        const image =
          values.image && values.image.length > 0
            ? values.image[0].originFileObj
            : null;
        if (!image && imageList.length === 0) {
          messageApi.error("Please enter image");
          return;
        }

        setFileUploading(true);
        const formData = new FormData();
        formData.append("influencer_id", values.influencer_id);
        formData.append("notification_id", values.notification_id);
        formData.append("message", values.message);
        formData.append("image", image);

        if (isEdit) {
          formData.append("update_id", values.update_id);
          formData.append("image_url", imageList[0].url);
          const { data } = await axios.put(
            SERVER_URL + "/influencer/notifications",
            formData
          );
          setData(data);
        } else {
          const { data } = await axios.post(
            SERVER_URL + "/influencer/notifications",
            formData
          );
          setData(data);
        }

        messageApi.open({
          type: "success",
          content: "Notification saved successfully!",
        });
        closeModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setFileUploading(false);
    }
  };

  const editDetail = (event, record) => {
    event.stopPropagation();
    setIsEdit(true);
    setIsModalOpen(true);
    form.setFieldsValue({
      update_id: record.id,
      influencer_id: record.influencer_id,
      notification_id: record.notification_id,
      message: record.message,
    });

    setImageList([
      {
        uid: record["image_url"],
        name: record["image_url"],
        status: "done",
        url: record["image_url"],
      },
    ]);
    openModal();
  };

  const handleOpenDeleteModal = (item_id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(item_id);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  };
  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(
        SERVER_URL + "/influencer/notifications",
        {
          data: {
            delete_id: deleteId,
          },
        }
      );
      setData(data);

      messageApi.open({
        type: "success",
        content: "Message deleted successfully!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => openModal(isModalOpen)}>
          Add Notification
        </Button>
      </div>

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataSource}
          rowKey="id"
          pagination={{
            position: ["topRight", "bottomRight"],
          }}
        />
      )}

      <Modal
        title={isEdit ? "Edit notification" : "Add notification"}
        okText={fileUploading ? "Uploading..." : "OK"}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={closeModal}
        okButtonProps={{ disabled: fileUploading }}
        cancelButtonProps={{ disabled: fileUploading }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="influencer_id"
            label="Influencer"
            rules={[{ required: true, message: "Please select influencer" }]}
          >
            <Select
              name="influencer_id"
              options={influencerOptions}
              rules={[{ required: true, message: "Please select influencer" }]}
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="message"
            label="Message"
            rules={[{ required: true, message: "Please tag message" }]}
          >
            <Input placeholder="Please tag message" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="notification_id"
            label="Notification"
            rules={[{ required: true, message: "Please select notification" }]}
          >
            <Select
              name="notification_id"
              options={[
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
              ]}
            />
          </Form.Item>

          <Form.Item name="image" label="Image" getValueFromEvent={normFile}>
            <Upload
              name="preview"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={imageList}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setImageList(e.fileList);
              }}
              onRemove={(e) => {
                setImageList([]);
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="update_id"
            style={{ display: "none" }}
          >
            <Input type="hidden" placeholder="" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Delete notification"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Delete Notification Message?</div>
      </Modal>

      <FreeMessagesSection />
      <br />
      <DemoMessagesSection />
      <br />
      <BonusContentHintSection />
    </div>
  );
};

export default InfluencerNotifications;
