import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  DatePicker,
} from "antd";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import axios from "../../utils/axios";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const dateFormat = "YYYY-MM-DD";

const InfluencerCloudflare = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [influencerOptions, setInfluencerOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const formItemLayout = null;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Influencer Id",
      dataIndex: "influencer_id",
      key: "influencer_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Influencer Name",
      dataIndex: "influencer_name",
      key: "influencer_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Server Name",
      dataIndex: "server_name",
      key: "server_name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Hourly Price of GPU ($)",
      dataIndex: "hourly_price_of_gpu",
      key: "hourly_price_of_gpu",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id);
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const fetchData = (key) => {
    customAxios.get(SERVER_URL + "/influencer/cloudflare").then(function (res) {
      const { cloudflare, influencers } = res.data.data;
      setDataSource(cloudflare);
      setInfluencerOptions(influencers);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData(null);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setUploading(false);
    form.resetFields();
  };
  const handleSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        const startDate = values.start_date.format(dateFormat);

        if (values["influencer_id"] === undefined) {
          messageApi.error("Please select influencer");
          return;
        }
        if (values["hourly_price_of_gpu"] === undefined) {
          messageApi.error("Please select hourly_price_of_gpu");
          return;
        }
        if (startDate === undefined) {
          messageApi.error("Please enter start_date");
          return;
        }

        setUploading(true);
        const endDate = values.end_date
          ? values.end_date.format(dateFormat)
          : null;
        const formData = new FormData();
        formData.append("influencer_id", values.influencer_id);
        formData.append("server_name", values.server_name);
        formData.append("start_date", startDate);
        formData.append("end_date", endDate);
        formData.append("hourly_price_of_gpu", values.hourly_price_of_gpu);

        if (isEdit) {
          formData.append("update_id", values.update_id);
          const { data } = await axios.put(
            SERVER_URL + "/influencer/cloudflare",
            formData
          );
          setDataSource(data);
        } else {
          const { data } = await axios.post(
            SERVER_URL + "/influencer/cloudflare",
            formData
          );
          setDataSource(data);
        }

        messageApi.open({
          type: "success",
          content: "Influencer cloudflare saved successfully!",
        });
        closeModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setUploading(false);
    }
  };

  const editDetail = (event, record) => {
    event.stopPropagation();
    setIsEdit(true);
    form.setFieldsValue({
      update_id: record.id,
      influencer_id: record.influencer_id,
      server_name: record.server_name,
      hourly_price_of_gpu: record.hourly_price_of_gpu,
      start_date: dayjs(record.start_date),
      end_date: dayjs(record.end_date),
    });

    openModal();
  };

  const handleOpenDeleteModal = (item_id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(item_id);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  };
  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(
        SERVER_URL + "/influencer/cloudflare",
        {
          data: {
            delete_id: deleteId,
          },
        }
      );
      setDataSource(data);

      messageApi.open({
        type: "success",
        content: "Message deleted successfully!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const disabledDate = (current) => {
    // Dates cannot be before 2024-02-01 or after today
    return current && (current < dayjs("2024-02-01") || current > dayjs());
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => openModal(isModalOpen)}>
          Add Cloudflare Data
        </Button>
      </div>

      {loading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataSource}
          rowKey="id"
          pagination={{
            position: ["topRight", "bottomRight"],
          }}
        />
      )}

      <Modal
        title={isEdit ? "Edit notification" : "Add notification"}
        okText={uploading ? "Uploading..." : "OK"}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={closeModal}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="influencer_id"
            label="Influencer"
            rules={[{ required: true, message: "Please select influencer" }]}
          >
            <Select
              name="influencer_id"
              options={influencerOptions}
              rules={[{ required: true, message: "Please select influencer" }]}
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="server_name"
            label="Server Name"
            rules={[{ required: false, message: "Please add server name" }]}
          >
            <Input placeholder="Please add server name" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="hourly_price_of_gpu"
            label="Hourly Price of GPU ($)"
            rules={[{ required: true, message: "Please add price" }]}
          >
            <Input placeholder="Please add price" />
          </Form.Item>

          <Form.Item
            label="Start Date"
            name="start_date"
            rules={[
              {
                required: true,
                message: "Please select start date",
              },
            ]}
          >
            <DatePicker
              minDate={dayjs("2024-02-01", dateFormat)}
              maxDate={dayjs(new Date().toDateString(), dateFormat)}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="end_date"
            rules={[
              {
                required: false,
                message: "Please select end date",
              },
            ]}
          >
            <DatePicker
              minDate={dayjs("2024-02-01", dateFormat)}
              maxDate={dayjs(new Date().toDateString(), dateFormat)}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="update_id"
            style={{ display: "none" }}
          >
            <Input type="hidden" placeholder="" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Delete notification"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Delete Notification Message?</div>
      </Modal>
    </div>
  );
};

export default InfluencerCloudflare;
