import React, {useEffect, useMemo, useRef, useState} from "react";
import {Button, Checkbox, Form, Input, message, Modal, Select, Table, Tag, Spin} from "antd";
import debounce from 'lodash/debounce';
import BarLoader from "react-spinners/BarLoader";
import customAxios from "../../utils/axios";
import {SERVER_URL} from "../../Constants";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import axios from "../../utils/axios";

const formItemLayout = null;

const UserInfluencer = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [influencerOptions, setInfluencerOptions] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [isSettings, setIsSettings] = useState(false);
  const [isBonusContent, setIsBonusContent] = useState(false);
  const [isStatistics, setIsStatistics] = useState(false);
  const [isEarnings, setIsEarnings] = useState(false);
  const [isCosts, setIsCosts] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const getUserInfluencersData = (current= 1, limit = 10) => {
    setLoading(true);
    customAxios.get(SERVER_URL + `/user-influencers?page=${current}&limit=${limit}`).then(function (res) {
      const { user_influencers, influencers, total  } = res.data;
      setData(user_influencers);
      setInfluencerOptions(influencers);
      setTotal(total);
      setLoading(false);
    });
  };

  useEffect(() => {
    // Fetch data from API
    getUserInfluencersData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>
    },
    {
      title: "E-Mail",
      dataIndex: "user_email",
      key: "user_email",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Influencer Name",
      dataIndex: "influencer_name",
      key: "influencer_name",
      render: (text) => <div>{text}</div>
    },
    {
      title: "Settings",
      dataIndex: "is_settings",
      key: "is_settings",
      render: (text) => <Tag color={text === 0 ? "red" : "green"}>{text === 0 ? "False" : "True"}</Tag>
    },
    {
      title: "Bonus Content",
      dataIndex: "is_bonus_content",
      key: "is_bonus_content",
      render: (text) => <Tag color={text === 0 ? "red" : "green"}>{text === 0 ? "False" : "True"}</Tag>
    },
    {
      title: "Statistics",
      dataIndex: "is_statistics",
      key: "is_statistics",
      render: (text) => <Tag color={text === 0 ? "red" : "green"}>{text === 0 ? "False" : "True"}</Tag>
    },
    {
      title: "Earnings",
      dataIndex: "is_earnings",
      key: "is_earnings",
      render: (text) => <Tag color={text === 0 ? "red" : "green"}>{text === 0 ? "False" : "True"}</Tag>
    },
    {
      title: "Cost",
      dataIndex: "is_costs",
      key: "is_costs",
      render: (text) => <Tag color={text === 0 ? "red" : "green"}>{text === 0 ? "False" : "True"}</Tag>
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{display: "flex", flexWrap: "wrap", gap: "0.5rem"}}>
          <Button
            type="primary"
            danger
            onClick={(event) => {editDetail(event, record)}}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id)
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      )
    }
  ];

  const openAddModal = () => {
    setIsOpenAddModal(true);
  };
  const closeAddModal = () => {
    setIsOpenAddModal(false);
    setIsEdit(false);
    setIsSubmit(false);
    form.resetFields();
    setIsSettings(false);
    setIsBonusContent(false);
    setIsStatistics(false);
    setIsEarnings(false);
    setIsCosts(false);
  };

  const handleOk = async () => {
    try {
      setIsSubmit(true);
      await form.validateFields().then(async (values) => {
        // Create a new FormData instance
        const formData = new FormData();
        const user_id = userOption.value;

        formData.append("user_id", user_id);
        formData.append("influencer_id", values["influencer_id"]);
        formData.append("is_settings", isSettings ? 1 : 0);
        formData.append("is_bonus_content", isBonusContent ? 1 : 0);
        formData.append("is_statistics", isStatistics ? 1 : 0);
        formData.append("is_earnings", isEarnings ? 1 : 0);
        formData.append("is_costs", isCosts ? 1 : 0);
        formData.append("limit", limit);
        formData.append("page_number", currentPage);

        if (isEdit) {
          formData.append("user_id", user_id);
          formData.append("user_influencer_id", values["id"]);
          formData.append("prev_influencer_id", values["prev_influencer_id"]);
          const res = await axios.put(SERVER_URL + "/user-influencers", formData);
          const { user_influencers, total } = res.data;
          setData(user_influencers);
          setTotal(total);
        } else {
          const res = await axios.post(SERVER_URL + "/user-influencers", formData);
          const { user_influencers, total } = res.data;
          setData(user_influencers);
          setTotal(total);
        }

        messageApi.open({
          type: "success",
          content: "Influencer added to user successfully!"
        });
        closeAddModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage = error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage
        });
      }
      setIsSubmit(false);
    }
  }

  function editDetail(event, record) {
    // console.log("record", record);
    event.stopPropagation();
    setIsEdit(true);
    form.setFieldsValue({
      id: record.id,
      influencer_id: record.influencer_id,
      prev_influencer_id: record.influencer_id,
      user_id: {label: record.user_email, value: record.user_id}
    });
    setIsSettings(record.is_settings === 1);
    setIsBonusContent(record.is_bonus_content === 1);
    setIsStatistics(record.is_statistics === 1);
    setIsEarnings(record.is_earnings === 1);
    setIsCosts(record.is_costs === 1);
    setUserOption({label: record.user_email, value: record.user_id});
    openAddModal();
  }

  const handleOpenDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  }
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  }
  const deleteRecord = async () => {
    try {
      const res = await axios.delete(SERVER_URL + "/user-influencers", {
        data: {
          id: deleteId,
          "limit": limit,
          "page_number": currentPage
        }
      });
      const { user_influencers, total } = res.data;
      setData(user_influencers);
      setTotal(total);

      messageApi.open({
        type: "success",
        content: "User influencer deleted!"
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message
      });
    }
  };

  const onChangeCheckbox = (e, name) => {
    const checked = e.target.checked;
    if (name === "is_settings") {
      setIsSettings(checked);
    } else if (name === "is_bonus_content") {
      setIsBonusContent(checked);
    } else if (name === "is_statistics") {
      setIsStatistics(checked);
    } else if (name === "is_earnings") {
      setIsEarnings(checked);
    } else if (name === "is_costs") {
      setIsCosts(checked);
    }
  }

  function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Form.Item
        {...formItemLayout}
        name="user_id"
        label="User Email"
        rules={[{required: true, message: "Please select user email"}]}
      >
        <Select
          labelInValue
          showSearch={true}
          filterOption={false}
          onSearch={debounceFetcher}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          {...props}
          options={options}
        />
      </Form.Item>
    );
  }

  async function fetchUserList(text) {
    const res = await customAxios.get(SERVER_URL + `/user-options?text=${text}`);
    return res.data["users"]
  }

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => openAddModal()}>
          Add influencer to user
        </Button>
      </div>

      {loading ?
        <BarLoader color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
        :
          <Table
            style={{ marginTop: "8px", marginBottom: "50px" }}
            columns={columns}
            dataSource={data}
            rowKey="id"
            onChange={(pagination) => {
              setCurrentPage(pagination.current);
              setLimit(pagination.pageSize);
              getUserInfluencersData(pagination.current, pagination.pageSize);
            }}
            pagination={
              {
                position: ["topRight", "bottomRight"],
                current: currentPage,
                pageSize: limit,
                total: total,
              }
            }
          />
      }

      <Modal
        title={isEdit ? "Edit User Influencer Connection" : "Add User Influencer Connection"}
        okText={isSubmit ? "Submitting..." : "OK"}
        open={isOpenAddModal}
        onOk={handleOk}
        onCancel={closeAddModal}
        width={640}
        okButtonProps={{ disabled:  isSubmit  }}
        cancelButtonProps={{ disabled:  isSubmit  }}
      >
        <Form
          form={form} name="dynamic_rule" layout="vertical"
        >
          <div style={{display: "flex", gap: "10px"}}>
            <div style={{width: "100%"}}>
              <DebounceSelect
                value={userOption}
                placeholder="Select user email"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  setUserOption(newValue);
                }}
                style={{
                  width: '100%',
                }}
              />
              <Form.Item
                {...formItemLayout}
                name="influencer_id"
                label="Influencer"
                rules={[{required: true, message: "Please select influencer"}]}
              >
                <Select
                  name="influencer_id"
                  options={influencerOptions}
                  rules={[{required: true, message: "Please select influencer"}]}
                />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="is_settings"
              >
                <Checkbox
                  checked={isSettings}
                  onChange={(e) => onChangeCheckbox(e, "is_settings")}
                >
                  Allow to edit influencer data
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="is_bonus_content"
              >
                <Checkbox
                  checked={isBonusContent}
                  onChange={(e) => onChangeCheckbox(e, "is_bonus_content")}
                >
                  Allow to add/edit bonus content
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="is_statistics"
              >
                <Checkbox
                  checked={isStatistics}
                  onChange={(e) => onChangeCheckbox(e, "is_statistics")}
                >
                  Allow access to statistics
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="is_earnings"
              >
                <Checkbox
                  checked={isEarnings}
                  onChange={(e) => onChangeCheckbox(e, "is_earnings")}
                >
                  Allow access to earnings
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="is_costs"
              >
                <Checkbox
                  checked={isCosts}
                  onChange={(e) => onChangeCheckbox(e, "is_costs")}
                >
                  Allow access to costs
                </Checkbox>
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="prev_influencer_id"
              >
                <Input type="hidden" placeholder=""/>
              </Form.Item>
              <Form.Item
                {...formItemLayout}
                name="id"
              >
                <Input type="hidden" placeholder=""/>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"Delete User Influencer connection"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Delete User Influencer connection?</div>
      </Modal>

    </div>
  )
}

export default UserInfluencer;
