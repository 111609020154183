import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary, withErrorBoundary } from "react-error-boundary";

const FallBackComp = () => {
  return (
    <div className="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <button onClick={window.location.reload()}>Reload</button>
    </div>
  );
};

const ComponentWithErrorBoundary = withErrorBoundary(App, {
  fallback: <FallBackComp />,
  onError(error, info) {
    console.log("--ERROR BOUNDARY--", error, info);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ComponentWithErrorBoundary />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
