import {Form, Input} from "antd";
import React from "react";

const WishlistSettings = (formItemLayout) => {
  return (
    <>
      <h2>Wishlist Settings:</h2>
      <Form.Item
        {...formItemLayout}
        name="no_wishlist_message"
        label="No Wishlist Message"
        rules={[{required: false, message: "Please input a no wishlist message)"}]}
      >
        <Input type="text" min={1} placeholder="Please input a No Wishlist Message)"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="wishlist_pre_message"
        label="Wishlist Pre Message"
        rules={[{required: false, message: "Please input a wishlist pre message)"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Wishlist Pre Message)"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="wishlist_link"
        label="Wishlist Link"
        rules={[{required: false, message: "Please input a wishlist link)"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Wishlist Link)"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="wishlist_post_message"
        label="Wishlist Post Message"
        rules={[{required: false, message: "Please input a wishlist post message)"}]}
      >
        <Input type="text" min={1} placeholder="Please input a Wishlist Post Message)"/>
      </Form.Item>
    </>
  )
}

export default WishlistSettings;
