import React, { useState } from "react";
import axios from "../../utils/axios";
import "./index.css";
import { Navigate, useNavigate } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  if (token) {
    return <Navigate to={"/"} />;
  }
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(SERVER_URL + "/login", {
        username: username,
        password: password
      });

      const { access_token } = data;
      localStorage.setItem("accessToken", access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      navigate("/");
      return;
    } catch (error) {
      console.error("Login failed:", error.response.data.message);
    }
  };

  return (
    <div className="login-container">
      <h1>Admin Login</h1>
      <form className="login-form" onSubmit={handleLogin}>
        <label>
          Username:
          <input type="text" value={username} onChange={handleUsernameChange} />
        </label>
        <br />
        <label>
          Password:
          <input type="password" value={password} onChange={handlePasswordChange} />
        </label>
        <br />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
