import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Radio,
  Input,
  Select,
  Upload,
  Image,
  message,
  Spin,
  Modal,
  Space,
  InputNumber,
} from "antd";
import { PlusSquareOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import { useDebounce } from "../../hooks/useDebounce";

const { TextArea } = Input;

const StyledRadioButton = styled(Radio.Button)`
  border-radius: 10px !important;
  border-inline-start: 1px solid #d9d9d9;

  &::before {
    display: none !important;
  }

  &.ant-radio-button-wrapper-checked::before {
    display: none !important;
  }
`;

const StyledCheckboxButton = styled.label`
  display: "inline-flex";
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
  font-size: 16px;

  &.checked {
    border-color: rgb(22, 119, 255);
    color: rgb(22, 119, 255);
  }
  &.checked:hover {
    color: #1890ff;
    border-color: #1890ff;
  }

  &:hover {
    color: rgb(22, 119, 255);
  }

  .hidden-checkbox {
    display: none;
  }
`;

const optionRender = (option) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span>
        {option.data.id} | {option.data.username || "None"} |{" "}
        {option.data.email}
      </span>
    </div>
  );
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ProgrammaticMessagesForm = ({
  form,
  formItemLayout,
  messagePeriods,
  setMessagePeriods,
  messageTriggers,
  setMessageTriggers,
  influencerList,
  fileImageList,
  setFileImageList,
  //   fileVideoList,
  //   setFileVideoList,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [openModal, setOpenModal] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState(1);
  const [selectedType, setSelectedType] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  //   const [previewVideo, setPreviewVideo] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userSearchText, setUserSearchText] = useState("");
  const debouncedSearchText = useDebounce(userSearchText, 300);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setTimeout(() => {
      form.setFieldsValue({ triggers: selectedOptions });
    }, 200);
  }, [form, selectedOptions]);

  useEffect(() => {
    if (debouncedSearchText.length >= 3) {
      setUserList([]);
      setIsFetching(true);

      customAxios
        .get(SERVER_URL + `/search-user-by-email?q=${debouncedSearchText}`)
        .then((res) => {
          setUserList(res.data);
        })
        .finally(() => setIsFetching(false));
    }
  }, [debouncedSearchText]);

  const handleCheckboxChange = (item, e) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedOptions((prevOptions) => [...prevOptions, item.id]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((id) => id !== item.id)
      );
    }
  };

  const onChangeImage = ({ fileList: newFileList }) => {
    setFileImageList(newFileList);
    // setFileVideoList([]);
  };

  //   const onChangeVideo = ({ fileList: newFileList }) => {
  //     setFileVideoList(
  //       newFileList.map((file) => ({
  //         ...file,
  //         url: URL.createObjectURL(file.originFileObj),
  //       }))
  //     );
  //     setFileImageList([]);
  //   };

  const onChangeSelectedType = ({ target }) => {
    const { value } = target;
    setSelectedType(value);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    // if (file?.type === "video/mp4" || file?.url?.includes(".mp4")) {
    //   setPreviewVideo(file.url || file.preview);
    // } else {
    setPreviewImage(file.url || file.preview);
    // }
  };

  const addMessageTrigger = () => {
    if (!!newOptionValue && !!selectedType) {
      setUploading(true);

      customAxios
        .post(SERVER_URL + "/programmatic_message_triggers", {
          newOptionValue,
          selectedType,
        })
        .then((res) => {
          setMessageTriggers(res.data);
          closeModal();
          messageApi.open({
            type: "success",
            content: "Option successfully added!",
          });
        })
        .catch(({ response }) => {
          setUploading(false);
          messageApi.open({
            type: "error",
            content: response.data.error,
          });
        });
    } else {
      messageApi.open({
        type: "error",
        content: "Please choose type and fill in it value!",
      });
    }
  };

  const addMessagePeriod = () => {
    if (!!newOptionValue && !!selectedType) {
      setUploading(true);
      customAxios
        .post(SERVER_URL + "/programmatic_message_periods", {
          newOptionValue,
          selectedType,
        })
        .then((res) => {
          setMessagePeriods(res.data);
          closeModal();
          messageApi.open({
            type: "success",
            content: "Option successfully added!",
          });
        })
        .catch(({ response }) => {
          setUploading(false);
          messageApi.open({
            type: "error",
            content: response.data.error,
          });
        });
    } else {
      messageApi.open({
        type: "error",
        content: "Please choose type and fill in it value!",
      });
    }
  };

  const removeMessageTrigger = (id) => {
    const foundMessageTrigger = messageTriggers.find(
      (messageTrigger) => messageTrigger.id === id
    );
    const filteredMessageTrigger = messageTriggers.filter(
      (messageTrigger) => messageTrigger.id !== id
    );
    setMessageTriggers(filteredMessageTrigger);

    customAxios
      .delete(
        SERVER_URL +
          `/programmatic_message_triggers?delete_id=${foundMessageTrigger.id}`
      )
      .catch(() => {
        messageApi.open({
          type: "error",
          content: "Can't delete current option!",
        });

        setMessageTriggers((prev) => [...prev, foundMessageTrigger]);
      });
  };

  const removeMessagePeriod = (id) => {
    const foundMessagePeriod = messagePeriods.find(
      (messagePeriod) => messagePeriod.id === id
    );
    const filteredMessagePeriod = messagePeriods.filter(
      (messagePeriod) => messagePeriod.id !== id
    );
    setMessagePeriods(filteredMessagePeriod);

    customAxios
      .delete(
        SERVER_URL +
          `/programmatic_message_periods?delete_id=${foundMessagePeriod.id}`
      )
      .catch(() => {
        messageApi.open({
          type: "error",
          content: "Can't delete current option!",
        });

        setMessagePeriods((prev) => [...prev, foundMessagePeriod]);
      });
  };

  const closeModal = () => {
    setOpenModal(null);
    setNewOptionValue(1);
    setUploading(false);
    setSelectedType(null);
  };

  return (
    <>
      {contextHolder}
      <Form form={form} name="dynamic_rule" layout="vertical">
        <Form.Item
          {...formItemLayout}
          style={{ marginTop: 30 }}
          name="triggers"
          label="Select the option of trigger"
          rules={[
            {
              required: true,
              message: "Please select the option of trigger",
            },
          ]}
        >
          <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
            <Button
              size="large"
              style={{ margin: 0 }}
              type="primary"
              onClick={() => setOpenModal("trigger")}
            >
              Create new
              <PlusSquareOutlined />
            </Button>

            {messageTriggers.map((item) => (
              <StyledCheckboxButton
                key={item.id}
                className={selectedOptions.includes(item.id) ? "checked" : ""}
              >
                <input
                  type="checkbox"
                  className="hidden-checkbox"
                  value={item.id}
                  checked={selectedOptions.includes(item.id)}
                  onChange={(e) => handleCheckboxChange(item, e)}
                />
                <span>
                  {item.label}
                  {!!item.is_deletable && (
                    <CloseOutlined
                      style={{ color: "#999", marginLeft: 8 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeMessageTrigger(item.id);
                      }}
                    />
                  )}
                </span>
              </StyledCheckboxButton>
            ))}
          </div>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={{ marginTop: 50 }}
          name="type"
          label="Select the type of notification"
          rules={[
            {
              required: true,
              message: "Please select the Type of Notification",
            },
          ]}
        >
          <Radio.Group
            style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
            size="large"
          >
            <StyledRadioButton value="chat">
              Notifications in the Chat
            </StyledRadioButton>
            <StyledRadioButton value="phone">
              Push Notification (phone)
            </StyledRadioButton>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={{ marginTop: 50 }}
          name="period"
          label="Select the option of period"
          rules={[
            {
              required: true,
              message: "Please select the option of period",
            },
          ]}
        >
          <Radio.Group
            style={{ display: "flex", flexWrap: "wrap", gap: 10 }}
            size="large"
          >
            <Button
              size="large"
              style={{ margin: 0 }}
              type="primary"
              onClick={() => setOpenModal("period")}
            >
              Create new
              <PlusSquareOutlined />
            </Button>

            {messagePeriods?.map((item) => (
              <StyledRadioButton key={item.id} value={item.id}>
                {item.label}{" "}
                {!!item.is_deletable && (
                  <CloseOutlined
                    style={{ color: "#999" }}
                    onClick={() => removeMessagePeriod(item.id)}
                  />
                )}
              </StyledRadioButton>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={{ marginTop: 50 }}
          name="influencer"
          label="Select an influencers"
          rules={[{ required: true, message: "Please select an influencer" }]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Please select an influencer"
            optionFilterProp="character"
            options={influencerList}
            fieldNames={{ label: "name", value: "id" }}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="user"
          label="Find and select an users"
        >
          <Select
            mode="multiple"
            allowClear
            showSearch
            filterOption={false}
            notFoundContent={isFetching ? <Spin size="small" /> : null}
            placeholder="Default is every user"
            optionFilterProp="label"
            options={userList}
            optionRender={optionRender}
            onSearch={setUserSearchText}
            fieldNames={{ label: "email", value: "id" }}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={{ marginTop: 50 }}
          name="title"
          label="Notification Title"
          rules={[
            { required: true, message: "Please type notification title" },
          ]}
        >
          <Input placeholder="Please type notification title" />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="message"
          label="Notification Message"
          rules={[
            { required: true, message: "Please type notification message" },
          ]}
        >
          <TextArea placeholder="Please type notification message" rows={4} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          style={{ marginTop: 50 }}
          name="image_urls"
          label="Select a photo"
        >
          <Upload
            accept=".png,.jpg"
            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
            beforeUpload={() => false}
            listType="picture-card"
            fileList={fileImageList}
            onChange={onChangeImage}
            onPreview={handlePreview}
            multiple
          >
            {fileImageList.length < 4 && "+ Select"}
          </Upload>
        </Form.Item>

        {/* <Form.Item {...formItemLayout} name="video_url" label="Select a video">
          <Upload
            accept=".mp4"
            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
            beforeUpload={() => false}
            listType="picture-card"
            fileList={fileVideoList}
            onChange={onChangeVideo}
            onPreview={handlePreview}
            multiple={false}
          >
            {fileVideoList.length < 1 && "+ Select"}
          </Upload>
        </Form.Item> */}

        <Form.Item {...formItemLayout} name="id" style={{ display: "none" }}>
          <Input type="hidden" placeholder="" />
        </Form.Item>
      </Form>

      <Modal
        title={"Create new trigger option"}
        okText={uploading ? "Uploading..." : "OK"}
        open={openModal === "trigger"}
        onOk={addMessageTrigger}
        onCancel={closeModal}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
      >
        <Space style={{ marginTop: 20 }} direction="vertical">
          <Radio.Group onChange={onChangeSelectedType} value={selectedType}>
            <Space direction="vertical">
              <Radio value="no_activity_last_hours">
                No activity in the last{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                hours
              </Radio>
              <Radio value="birthday_days_period">
                Birthday{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                days before and after
              </Radio>
              <Radio value="account_not_older_then_days">
                Account not older than{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                days
              </Radio>
              <Radio value="account_older_then_days">
                Account older than{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                days
              </Radio>
              <Radio value="balance_less_then_dollars">
                Balance less then{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                dollars
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
      </Modal>

      <Modal
        title={"Create new period option"}
        okText={uploading ? "Uploading..." : "OK"}
        open={openModal === "period"}
        onOk={addMessagePeriod}
        onCancel={closeModal}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
      >
        <Space style={{ marginTop: 20 }} direction="vertical">
          <Radio.Group onChange={onChangeSelectedType} value={selectedType}>
            <Space direction="vertical">
              <Radio value="every_messages">
                Every{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                messages
              </Radio>
              <Radio value="after_messages">
                After{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                messages
              </Radio>
              <Radio value="every_hours">
                Every{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                hours
              </Radio>
              <Radio value="after_hours">
                After{" "}
                <InputNumber
                  min={1}
                  value={newOptionValue}
                  onChange={setNewOptionValue}
                />{" "}
                hours
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
      </Modal>

      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: !!previewImage,
            onVisibleChange: (visible) => setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}

      {/* {previewVideo && (
        <Modal
          title={"Video preview"}
          okText={"OK"}
          open={!!previewVideo}
          onCancel={() => setPreviewVideo("")}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => setPreviewVideo("")}
            >
              OK
            </Button>,
          ]}
        >
          <video style={{ marginTop: 20 }} width="100%" controls>
            <source src={previewVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      )} */}
    </>
  );
};

export default ProgrammaticMessagesForm;
