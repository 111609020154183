import React, { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { Button, Form, message, Modal, Table } from "antd";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import axios from "../../utils/axios";
import ProgrammaticMessagesForm from "./Form";
import dayjs from "dayjs";

const defaultInfluencerList = [
  {
    id: 0,
    name: "Last influencer",
  },
];

const SubscriptionPlans = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [messagePeriods, setMessagePeriods] = useState([]);
  const [messageTriggers, setMessageTriggers] = useState([]);
  const [influencerList, setInfluencerList] = useState(defaultInfluencerList);
  const [fileImageList, setFileImageList] = useState([]);
  //   const [fileVideoList, setFileVideoList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const formItemLayout = null;

  const columns = [
    {
      title: "Title",
      dataIndex: "message_title",
      key: "message_title",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Message",
      dataIndex: "message_text",
      key: "message_text",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Date of creation",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <>
          <div>{dayjs(text).format("YYYY-MM-DD")}</div>
          <div>{dayjs(text).format("HH:MM")}</div>
        </>
      ),
    },
    {
      title: "Last launch",
      dataIndex: "launched_at",
      key: "launched_at",
      render: (text) =>
        !!text ? (
          <>
            <div>{dayjs(text).format("YYYY-MM-DD")}</div>
            <div>{dayjs(text).format("HH:MM")}</div>
          </>
        ) : (
          <div>-</div>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (text, record) => (
        <div>
          <Button
            style={{ marginTop: 0, width: 150 }}
            type="primary"
            title={"Status"}
          >
            {record.status}
          </Button>
          <Button
            style={{ width: 150 }}
            type="primary"
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Open Campaign"}
          >
            Edit Campaign
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    customAxios.get(SERVER_URL + "/programmatic_messages").then((res) => {
      setDataSource(res.data.programmatic_messages);
      setMessagePeriods(res.data.programmatic_message_periods);
      setMessageTriggers(res.data.programmatic_messages_triggers);
      setInfluencerList((prev) => [...prev, ...res.data.influencers]);

      setLoading(false);
    });
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUploading(false);
    setIsEdit(false);
    setFileImageList([]);
    // setFileVideoList([]);
    setSelectedOptions([]);
    form.resetFields();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        setUploading(true);

        const oldPhotos = fileImageList.filter((item) => !!item.url);
        const newPhotos = fileImageList.filter((item) => !item.url);

        const formData = new FormData();
        formData.append("triggers", JSON.stringify(values.triggers || []));
        formData.append("type", values.type);
        formData.append("period", values.period);
        formData.append("influencer", values.influencer);
        formData.append("user", JSON.stringify(values.user || []));
        formData.append("title", values.title);
        formData.append("message", values.message);
        formData.append(
          "images",
          JSON.stringify(oldPhotos.map((i) => i.url) || [])
        );
        newPhotos.forEach((file) => {
          formData.append("images", file.originFileObj);
        });
        // if (fileVideoList.length > 0) {
        //   formData.append(
        //     "video",
        //     fileVideoList[0]?.originFileObj
        //       ? fileVideoList[0].originFileObj
        //       : fileVideoList[0].url
        //   );
        // }

        if (isEdit) {
          formData.append("update_id", values.id);
          const { data } = await axios.put(
            SERVER_URL + "/programmatic_messages",
            formData
          );
          setDataSource(data);
        } else {
          const { data } = await axios.post(
            SERVER_URL + "/programmatic_messages",
            formData
          );
          setDataSource(data);
        }

        messageApi.open({
          type: "success",
          content: "Campaign successfully created!",
        });
        closeModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setUploading(false);
    }
  };

  const editDetail = (event, record) => {
    event.stopPropagation();
    setIsEdit(true);
    form.setFieldsValue({
      id: record.id,
      triggers: record.triggers,
      type: record.type,
      period: record.period_id,
      influencer: record.influencer_id,
      user: JSON.parse(record.user_ids),
      title: record.message_title,
      message: record.message_text,
      image_urls: JSON.parse(record.photo_urls),
      //   video_url: record.video_url,
    });
    if (record.triggers) {
      setSelectedOptions(JSON.parse(record.triggers));
    }
    if (record.photo_urls) {
      setFileImageList(
        JSON.parse(record.photo_urls).map((item) => ({
          url: item,
        }))
      );
    }
    // if (record.video_url) {
    //   setFileVideoList([{ url: record.video_url }]);
    // }

    openModal();
  };

  const handleOpenDeleteModal = (item_id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(item_id);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  };

  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(SERVER_URL + "/subscription/plans", {
        data: {
          delete_id: deleteId,
        },
      });
      setDataSource(data);

      messageApi.open({
        type: "success",
        content: "Plan deleted successfully!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          onClick={() => openModal(isModalOpen)}
        >
          Create Campaign
        </Button>
      </div>

      {loading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataSource}
          rowKey="id"
        />
      )}

      <Modal
        title={isEdit ? "Edit Campaign" : "Create Campaign"}
        okText={uploading ? "Uploading..." : "OK"}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={closeModal}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
        width={1000}
      >
        <ProgrammaticMessagesForm
          form={form}
          formItemLayout={formItemLayout}
          messagePeriods={messagePeriods}
          setMessagePeriods={setMessagePeriods}
          messageTriggers={messageTriggers}
          setMessageTriggers={setMessageTriggers}
          influencerList={influencerList}
          fileImageList={fileImageList}
          setFileImageList={setFileImageList}
          //   fileVideoList={fileVideoList}
          //   setFileVideoList={setFileVideoList}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      </Modal>

      <Modal
        title={"Campaign deletion"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Are you sure you want delete current campaign?</div>
      </Modal>
    </div>
  );
};

export default SubscriptionPlans;
