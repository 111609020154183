import {Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

const BonusContent = (formItemLayout) => {
  return (
    <>
      <h2>Bonus Content:</h2>
      <Form.Item
        {...formItemLayout}
        name="bonusContent_hint_msgs"
        label="The text that will be sent when a bonus content hint message is sent. [Split each message by new line]"
        rules={[{
          required: false,
          message: "The text that will be sent when a bonus content hint message is sent."
        }]}
      >
        <TextArea rows={16}
                  placeholder="The text that will be sent when a bonus content hint message is sent." />
      </Form.Item>
    </>
  )
}

export default BonusContent;
