import React, { useEffect, useState } from "react";
import { Tooltip, DatePicker, message, Table, Tabs, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import BarLoader from "react-spinners/BarLoader";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const { RangePicker } = DatePicker;
const today = new Date().toISOString().split("T")[0];

const ReferralCodes = () => {
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [customRangeDate, setCustomRangeData] = useState([
    dayjs(today, "YYYY-MM-DD").utc(true),
    dayjs(today, "YYYY-MM-DD").utc(true),
  ]);
  const [dataToday, setDataToday] = useState([]);
  const [dataYesterday, setDataYesterday] = useState([]);
  const [data7Days, setData7Days] = useState([]);
  const [data30Days, setData30Days] = useState([]);
  const [data90Days, setData90Days] = useState([]);
  const [data365Days, setData365Days] = useState([]);
  const [dataCustom, setDataCustom] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  const handleFetchCustomDate = async (date) => {
    setLoading(true);
    const res = await customAxios.get(
      SERVER_URL +
        `/referrals?interval=custom&startDate=${dayjs(date[0])
          .utc(true)
          .format("YYYY-MM-DD")}&endDate=${dayjs(date[1])
          .utc(true)
          .format("YYYY-MM-DD")}`
    );
    setDataCustom(res.data);
    setLoading(false);
  };

  const preparePostContent = (posts) => {
    const columnsOfPosts = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",
        align: "center",
      },
    ];

    const dataSource = [];
    const arrOfPosts = posts?.split(", ") || [];

    arrOfPosts.forEach((post) =>
      dataSource.push({
        title: post.split(":")[0],
        count: post.split(":")[1],
      })
    );

    return (
      <Table
        dataSource={dataSource}
        columns={columnsOfPosts}
        pagination={false}
        size="small"
      />
    );
  };

  const columns = [
    {
      title: "Referral Code",
      dataIndex: "referral_code",
      key: "referral_code",
      render: (text) => <div>{text}</div>,
    },
    {
      title: (
        <>
          <Tooltip
            placement="top"
            title="The number of users who came using this referral code for the selected period"
          >
            Users Count <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "count",
      key: "count",
      render: (text) => <div>{text}</div>,
    },
    {
      title: (
        <>
          <Tooltip
            placement="top"
            title="The amount of profit for the selected period from users who came using the specified referral code"
          >
            Total Charges <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "charges",
      key: "charges",
      render: (text) => <div>${text}.00</div>,
    },
    {
      title: (
        <Tooltip
          placement="top"
          title="You can click on the value for getting more detailed information"
        >
          Packages <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "packages",
      key: "packages",
      render: (text, record) => (
        <div>
          <Popover
            content={() => preparePostContent(record.posts)}
            trigger="click"
          >
            <div style={{ cursor: "pointer" }}>{text}</div>
          </Popover>
        </div>
      ),
    },
    {
      title: "Subscriptions",
      dataIndex: "subscriptions",
      key: "subscriptions",
      render: (text) => <div>0</div>,
    },
  ];

  const items = [
    {
      key: "1",
      label: "Today",
      children: (
        <Table
          key="idToday"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataToday}
          rowKey={(record) => record.referral_code}
          pagination={{
            position: ["topRight", "bottomRight"],
            pageSize: 15,
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Yesterday",
      children: (
        <Table
          key="idYesterday"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={dataYesterday}
          rowKey={(record) => record.referral_code}
          pagination={{
            position: ["topRight", "bottomRight"],
            pageSize: 15,
          }}
        />
      ),
    },
    {
      key: "3",
      label: "Past 7 Days",
      children: (
        <Table
          key="id7Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data7Days}
          rowKey={(record) => record.referral_code}
          pagination={{
            position: ["topRight", "bottomRight"],
            pageSize: 15,
          }}
        />
      ),
    },
    {
      key: "4",
      label: "Past 30 Days",
      children: (
        <Table
          key="id30Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data30Days}
          rowKey={(record) => record.referral_code}
          pagination={{
            position: ["topRight", "bottomRight"],
            pageSize: 15,
          }}
        />
      ),
    },
    {
      key: "5",
      label: "Last 90 days",
      children: (
        <Table
          key="id90Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data90Days}
          rowKey={(record) => record.referral_code}
          pagination={{
            position: ["topRight", "bottomRight"],
            pageSize: 15,
          }}
        />
      ),
    },
    {
      key: "6",
      label: "Last year",
      children: (
        <Table
          key="id365Days"
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data365Days}
          rowKey={(record) => record.referral_code}
          pagination={{
            position: ["topRight", "bottomRight"],
            pageSize: 15,
          }}
        />
      ),
    },
    {
      key: "7",
      label: (
        <RangePicker
          size="small"
          value={customRangeDate}
          onChange={(date) => {
            setCustomRangeData(date);
            handleFetchCustomDate(date);
          }}
          maxDate={dayjs(new Date().toISOString().split("T")[0], "YYYY-MM-DD")}
          allowClear={false}
        />
      ),
      children: (
        <>
          <Table
            key="idCustomDays"
            style={{ marginTop: "8px" }}
            columns={columns}
            dataSource={dataCustom}
            rowKey={(record) => record.referral_code}
            pagination={{
              position: ["topRight", "bottomRight"],
              pageSize: 15,
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getReferralData();
  }, []);

  const getReferralData = () => {
    customAxios
      .get(SERVER_URL + "/referrals?interval=today")
      .then(function (res) {
        setDataToday(res.data);
        setLoading(false);
      });
  };

  const onChange = async (key) => {
    setActiveKey(key);
    if (key === "2" && dataYesterday && dataYesterday.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=yesterday"
      );
      setDataYesterday(res.data);
      setLoading(false);
    } else if (key === "3" && data7Days && data7Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_7_days"
      );
      setData7Days(res.data);
      setLoading(false);
    } else if (key === "4" && data30Days && data30Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_30_days"
      );
      setData30Days(res.data);
      setLoading(false);
    } else if (key === "5" && data90Days && data90Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_90_days"
      );
      setData90Days(res.data);
      setLoading(false);
    } else if (key === "6" && data365Days && data365Days.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL + "/referrals?interval=last_365_days"
      );
      setData365Days(res.data);
      setLoading(false);
    } else if (key === "7" && dataCustom && dataCustom.length === 0) {
      setLoading(true);
      const res = await customAxios.get(
        SERVER_URL +
          `/referrals?interval=custom&startDate=${dayjs(customRangeDate[0])
            .utc(true)
            .format("YYYY-MM-DD")}&endDate=${dayjs(customRangeDate[1])
            .utc(true)
            .format("YYYY-MM-DD")}`
      );
      setDataCustom(res.data);
      setLoading(false);
    }
  };

  return (
    <div>
      {contextHolder}

      {loading ? (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      ) : (
        <Tabs activeKey={activeKey} items={items} onChange={onChange} />
      )}
    </div>
  );
};

export default ReferralCodes;
