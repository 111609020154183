import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Select,
  message,
  Tag,
  Popconfirm,
} from "antd";
import React, { useState, useEffect, useMemo } from "react";
import customAxios from "../../utils/axios";
import axios from "axios";
import SelectInfluencerDropDown from "./SelectInfluencersDropDown";
import styles from "./styles.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import BarLoader from "react-spinners/BarLoader";

// export default DateRangeFilter;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;
const formItemLayout = null;

const PromoCodes = () => {
  const [loading, setLoading] = useState(true);

  const [influencersData, setInfluencersData] = useState([]);

  const [amount, setAmount] = useState(0);
  const [value, setValue] = useState(0);

  const [selectedCodes, setSelectedCodes] = useState([]);

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  const handleValueChange = (value) => {
    setValue(value);
  };

  const handleSelectChange = (checked, code) => {
    console.log(checked, code);
    console.log(selectedCodes);

    if (checked) {
      setSelectedCodes([...selectedCodes, code]);
    } else {
      setSelectedCodes(selectedCodes.filter((item) => item !== code));
    }
  };

  class ExportButton extends React.Component {
    generatePromoCodes = async () => {
      try {
        if (amount == 0 || value == 0) {
          alert("Please enter a amount and value");
          return;
        }
        const { data } = await customAxios.post(
          SERVER_URL + "/promo_codes/generate",
          {
            amount: amount,
            value: value,
          }
        );
        console.log("data", data);
        setInfluencersData(data);
        setLoading(false);
      } catch (error) {
        console.log("--ERROR--", error);
      }
    };

    markAsDistributed = async () => {
      try {
        if (selectedCodes.length == 0) {
          alert("Please select some codes");
          return;
        }
        const { data } = await customAxios.post(
          SERVER_URL + "/promo_codes/mark_as_distributed/",
          {
            codes: selectedCodes.toString(","),
            distributed: 1,
          }
        );
        console.log("data", data);
        setInfluencersData(data);
        setLoading(false);
      } catch (error) {
        console.log("--ERROR--", error);
      }
    };

    markAsNotDistributed = async () => {
      try {
        if (selectedCodes.length == 0) {
          alert("Please select some codes");
          return;
        }
        const { data } = await customAxios.post(
          SERVER_URL + "/promo_codes/mark_as_distributed/",
          {
            codes: selectedCodes.toString(","),
            distributed: 0,
          }
        );
        console.log("data", data);
        setInfluencersData(data);
        setLoading(false);
      } catch (error) {
        console.log("--ERROR--", error);
      }
    };

    render() {
      return (
        <>
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={this.generatePromoCodes}
          >
            Generate Promo Codes
          </Button>
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={this.markAsDistributed}
          >
            Mark as Distributed
          </Button>
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={this.markAsNotDistributed}
          >
            Mark as Not Distributed
          </Button>
        </>
      );
    }
  }

  const tableData = useMemo(() => {
    const arr = [];

    console.log("influencersData", influencersData);
    if (influencersData.promo_codes == undefined) return arr;

    // If selectedInfluencer is empty or "all" is selected, return all influencer data without any filter
    influencersData.promo_codes.forEach((influencer) => {
      arr.push({ ...influencer });
    });

    console.log("arr", arr);
    return arr;
  }, [influencersData]);

  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "Select",
      key: "code",
      dataIndex: "code",
      render: (code) => (
        <Input
          type="checkbox"
          onChange={(e) => handleSelectChange(e.target.checked, code)}
        />
      ),
    },
    {
      title: "Code",
      key: "code",
      dataIndex: "code",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      render: (text) => (
        <Tag color={text == "0" ? "red" : "green"}>
          {text == "0" ? "False" : "True"}
        </Tag>
      ),
    },
    {
      title: "Distributed",
      dataIndex: "distributed",
      key: "distributed",
      render: (text) => (
        <Tag color={text == "0" ? "red" : "green"}>
          {text == "0" ? "False" : "True"}
        </Tag>
      ),
    },
  ];

  useEffect(() => {
    getInfluencerData();
  }, []);

  const getInfluencerData = async () => {
    try {
      const { data } = await customAxios.get(SERVER_URL + "/promo_codes/");
      console.log("data", data);
      setInfluencersData(data);
      setLoading(false);
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Input
          style={{ width: "30vw", marginRight: "10px" }}
          type="Number"
          placeholder="Number of codes"
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <Input
          style={{ width: "30vw", marginRight: "10px" }}
          type="Number"
          placeholder="Value"
          onChange={(e) => handleValueChange(e.target.value)}
        />
      </div>
      <div>
        <ExportButton></ExportButton>
      </div>
      <div>
        {loading && (
          <BarLoader
            color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        )}
        {!loading && (
          <Table
            style={{ marginTop: "8px" }}
            columns={columns}
            dataSource={tableData}
            rowKey="id"
            pagination={{
              position: ["topRight", "bottomRight"],
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PromoCodes;
