import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Upload,
  Switch,
} from "antd";
import { SERVER_URL } from "../../Constants";
import axios from "../../utils/axios";
import { UploadOutlined } from "@ant-design/icons";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const PlansForm = ({
  isPlanModalOpen,
  isEdit,
  formRecords,
  setFormRecords,
  uploading,
  setUploading,
  setDataSource,
  setIsPlanModalOpen,
  setIsEdit,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [previewList, setPreviewList] = useState([]);
  const formItemLayout = null;

  const [form] = Form.useForm();

  const closePlanModal = () => {
    setIsPlanModalOpen(false);
    setUploading(false);
    setIsEdit(false);
    setPreviewList([]);
    setFormRecords({});
    form.resetFields();
  };

  const fillInPlanDetails = useCallback(
    (record) => {
      form.setFieldsValue({
        id: record.id,
        title: record.title,
        page_slug: record.page_slug,
        amount: record.amount,
        first_amount: record.first_amount,
        afterward_amount: record.afterward_amount,
        token_amount: record.token_amount,
        afterward_token_amount: record.afterward_token_amount,
        free_text_messages: record.free_text_messages,
        free_audio_messages: record.free_audio_messages,
        free_image_generations: record.free_image_generations,
        free_posts: record.free_posts,
        token_purchase_discount: record.token_purchase_discount,
        frequency: record.frequency,
        is_active: record.is_active,
        show_in_subscription_popup: record.show_in_subscription_popup,
        is_pin_tour: record.is_pin_tour,
        image_url: record.image_url,
        features: JSON.parse(record.features),
      });
      setPreviewList([{ url: record.image_url }]);
    },
    [form]
  );

  useEffect(() => {
    if (formRecords?.id) {
      fillInPlanDetails(formRecords);
    }
  }, [fillInPlanDetails, formRecords]);

  const handleSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        setUploading(true);

        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("page_slug", values.page_slug);
        formData.append("amount", values.first_amount);
        formData.append("first_amount", values.first_amount);
        formData.append("afterward_amount", values.afterward_amount);
        formData.append("token_amount", values.token_amount);
        formData.append(
          "afterward_token_amount",
          values.afterward_token_amount
        );
        formData.append("free_text_messages", values.free_text_messages);
        formData.append("free_audio_messages", values.free_audio_messages);
        formData.append(
          "free_image_generations",
          values.free_image_generations
        );
        formData.append("free_posts", values.free_posts);
        formData.append(
          "token_purchase_discount",
          values.token_purchase_discount
        );
        formData.append("frequency", values.frequency);
        formData.append("is_active", +values.is_active);
        formData.append(
          "show_in_subscription_popup",
          +values.show_in_subscription_popup
        );
        formData.append("is_pin_tour", +values.is_pin_tour);
        formData.append(
          "image",
          values.image_url && values.image_url.length > 0
            ? typeof values.image_url === "string"
              ? values.image_url
              : values.image_url[0].originFileObj
            : null
        );
        formData.append(
          "features",
          JSON.stringify(values.features?.map((feature) => feature))
        );

        if (isEdit) {
          formData.append("update_id", values.id);
          const { data } = await axios.put(
            SERVER_URL + "/subscription/plans",
            formData
          );
          setDataSource(data);
        } else {
          const { data } = await axios.post(
            SERVER_URL + "/subscription/plans",
            formData
          );
          setDataSource(data);
        }

        messageApi.open({
          type: "success",
          content: "Subscription plan saved successfully!",
        });
        closePlanModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setUploading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={isEdit ? "Edit plan" : "Add plan"}
        okText={uploading ? "Uploading..." : "OK"}
        open={isPlanModalOpen}
        onOk={handleSubmit}
        onCancel={closePlanModal}
        okButtonProps={{ disabled: uploading }}
        cancelButtonProps={{ disabled: uploading }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="title"
            label="Plan title"
            rules={[{ required: true, message: "Please add plan title" }]}
          >
            <Input placeholder="Please add server name" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="page_slug"
            label="Page slug"
            rules={[
              { required: true, message: "Please add page slug" },
              {
                pattern: /^[a-z]+(-[a-z]+)*$/,
                message: "Only lowercase letters and internal hyphens allowed",
              },
            ]}
          >
            <Input placeholder="Please add page slug" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="frequency"
            label="Frequency"
            rules={[{ required: true, message: "Please select frequency" }]}
          >
            <Select
              name="frequency"
              placeholder="Please select frequency"
              options={[
                { value: "weekly", label: "Weekly" },
                { value: "monthly", label: "Monthly" },
                { value: "yearly", label: "Yearly" },
              ]}
              rules={[{ required: true, message: "Please select frequency" }]}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="first_amount"
            label="First amount ($)"
            rules={[
              { required: true, message: "Please add First amount" },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add First amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="afterward_amount"
            label="Afterward amount ($)"
            rules={[
              { required: true, message: "Please add afterward amount" },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add afterward amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="token_amount"
            label="Tokens amount"
            rules={[
              { required: true, message: "Please add tokens amount" },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add tokens amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="afterward_token_amount"
            label="Afterward tokens amount"
            rules={[
              { required: true, message: "Please add afterward tokens amount" },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add afterward tokens amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="free_text_messages"
            label="Free Text messages"
            rules={[
              {
                required: true,
                message: "Please add free text messages amount",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add free text messages amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="free_audio_messages"
            label="Free Audio messages"
            rules={[
              {
                required: true,
                message: "Please add free audio messages amount",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add free audio messages amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="free_image_generations"
            label="Free Image generations"
            rules={[
              {
                required: true,
                message: "Please add free image generations amount",
              },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add free image generations amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="free_posts"
            label="Free Posts"
            rules={[
              { required: true, message: "Please add free posts amount" },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add free posts amount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="token_purchase_discount"
            label="Token purchase discount (%)"
            rules={[
              { required: true, message: "Please add token purchase discount" },
              {
                validator: (_, value) =>
                  value >= 0
                    ? Promise.resolve()
                    : Promise.reject("Amount cannot be negative"),
              },
            ]}
          >
            <Input
              placeholder="Please add token purchase discount"
              type="number"
              min={0}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status" }]}
          >
            <Switch
              name="is_active"
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              value={form.getFieldValue("is_active")}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="show_in_subscription_popup"
            label="Show in subscription popup"
            rules={[{ required: true, message: "Please select show in popup" }]}
          >
            <Switch
              name="show_in_subscription_popup"
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              value={form.getFieldValue("show_in_subscription_popup")}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="is_pin_tour"
            label="Show in pin-tour pages"
            rules={[
              { required: true, message: "Please select show in pin-tour" },
            ]}
          >
            <Switch
              name="is_pin_tour"
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              value={form.getFieldValue("is_pin_tour")}
            />
          </Form.Item>
          <Form.Item
            name="image_url"
            label="Plan image (should be vertical)"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "Please select a picture" }]}
          >
            <Upload
              name="image_url"
              action={
                "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              }
              listType="picture"
              fileList={previewList}
              multiple={false}
              beforeUpload={() => false}
              maxCount={1}
              onChange={(e) => {
                setPreviewList(e.fileList);
              }}
              onRemove={(e) => {
                setPreviewList([]);
              }}
              rules={[{ required: true, message: "Please select a picture" }]}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="features"
            label="Features"
            rules={[
              { required: true, message: "Please set at least one feature" },
            ]}
          >
            <Select mode="tags" placeholder="Please fill in features" />
          </Form.Item>
          <Form.Item {...formItemLayout} name="id" style={{ display: "none" }}>
            <Input type="hidden" placeholder="" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PlansForm;
