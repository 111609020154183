import React from "react";

const InfluencerTags = (tags) => {
  if (tags.tags.length === 0) {
    return <div>No tags</div>
  }

  const elems = tags.tags.map((tag, index) => {
    return <div
      key={tag.id}
      style={{
      display: "flex",
      "justifyContent": "center",
      width: "100%",
      "paddingLeft": "5px",
      "paddingRight": "5px",
      "borderRadius": "12px",
      "backgroundColor": "#e7e7e7"
    }}>{tag.title}</div>
  })

  return (
    <div style={{display: "flex", "flexDirection": "column", "rowGap": "4px"}}>
      {elems}
    </div>
  )
}

export default InfluencerTags;
