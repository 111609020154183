import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Modal, Table } from "antd";
import BarLoader from "react-spinners/BarLoader";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";

const formItemLayout = null;
const { TextArea } = Input;

const Starter = () => {
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([
    {
      id: "",
      title: "",
      description: "",
      is_active: "",
    },
  ]);

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (number) => (number ? <div>Yes</div> : <div>No</div>),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              editDetail(event, record);
            }}
            title={"Edit"}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            danger
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteModal(record.id);
            }}
            title={"Delete"}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      axios.get(SERVER_URL + "/chat-starter").then(function (res) {
        const { data } = res.data;
        setData(data);
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  const openAddModal = () => {
    setIsOpenAddModal(true);
  };
  const closeAddModal = () => {
    setIsOpenAddModal(false);
    setIsEdit(false);
    setIsSubmit(false);
    setIsActive(false);
    form.resetFields();
  };
  const handleOk = async () => {
    try {
      setIsSubmit(true);
      await form.validateFields().then(async (values) => {
        // Create a new FormData instance
        const formData = new FormData();
        formData.append("title", values["title"]);
        formData.append("description", values["description"]);
        formData.append("is_active", isActive ? 1 : 0);

        if (isEdit) {
          formData.append("id", values.id);
          const { data } = await axios.put(
            SERVER_URL + "/chat-starter",
            formData
          );
          setData(data);
        } else {
          const { data } = await axios.post(
            SERVER_URL + "/chat-starter",
            formData
          );
          setData(data);
        }

        messageApi.open({
          type: "success",
          content: "Starter saved successfully!",
        });
        closeAddModal();
      });
    } catch (error) {
      console.log("--ERROR--", error);
      if (error.response) {
        // Try to get the error message from the server otherwise set it to a default message
        const errorMessage =
          error.response.data.error || "Something went wrong!";

        messageApi.open({
          type: "error",
          content: errorMessage,
        });
      }
      setIsSubmit(false);
    }
  };

  function editDetail(event, record) {
    event.stopPropagation();
    setIsEdit(true);
    setIsActive(record.is_active === 1);
    form.setFieldsValue({
      id: record.id,
      title: record.title,
      description: record.description,
      is_active: record.is_active === 1,
    });
    openAddModal();
  }

  const handleOpenDeleteModal = (id) => {
    setIsDeleteModalOpen(true);
    setDeleteId(id);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteId("");
  };
  const deleteRecord = async () => {
    try {
      const { data } = await axios.delete(SERVER_URL + "/chat-starter", {
        data: {
          id: deleteId,
        },
      });
      setData(data);

      messageApi.open({
        type: "success",
        content: "Starter deleted!",
      });
      handleCloseDeleteModal();
    } catch (error) {
      console.log("--ERROR--", error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const onChange = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <div>
      {contextHolder}
      <div>
        <Button type="primary" onClick={() => openAddModal()}>
          Add Starter
        </Button>
      </div>
      <div>
        {loading && (
          <BarLoader
            color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        )}
        {!loading && (
          <Table
            style={{ marginTop: "8px" }}
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{
              position: ["topRight", "bottomRight"],
            }}
          />
        )}
      </div>

      <Modal
        title={isEdit ? "Edit Starter" : "Add Starter"}
        okText={isSubmit ? "Submitting..." : "OK"}
        open={isOpenAddModal}
        onOk={handleOk}
        onCancel={closeAddModal}
        width={640}
        okButtonProps={{ disabled: isSubmit }}
        cancelButtonProps={{ disabled: isSubmit }}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "100%" }}>
              <Form.Item
                {...formItemLayout}
                name="title"
                label="Title"
                rules={[{ required: true, message: "Please input title" }]}
              >
                <Input type="text" placeholder="Please input title" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                name="description"
                label="Description"
                rules={[{ required: true, message: "Please add description" }]}
              >
                <TextArea rows={3} placeholder="Please input description" />
              </Form.Item>

              <Form.Item {...formItemLayout} name="is_active">
                <Checkbox checked={isActive} onChange={onChange}>
                  Is Active
                </Checkbox>
              </Form.Item>

              <Form.Item {...formItemLayout} name="id">
                <Input type="hidden" placeholder="" />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        title={"Delete starter"}
        open={isDeleteModalOpen}
        onOk={deleteRecord}
        onCancel={handleCloseDeleteModal}
      >
        <div>Delete Starter?</div>
      </Modal>
    </div>
  );
};

export default Starter;
