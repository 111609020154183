import {Form, Input} from "antd";
import React from "react";

const TipSettings = (formItemLayout) => {
  return (
    <>
      <h2>Tip Settings:</h2>
      <Form.Item
        {...formItemLayout}
        name="tip_pre_message"
        label="Tip Pre Message"
        rules={[{required: true, message: "Please input tip_pre_message)"}]}
      >
        <Input type="text" min={1} placeholder="Please input tip_pre_message)"/>
      </Form.Item>
    </>
  )
}

export default TipSettings;
