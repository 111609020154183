import BarLoader from "react-spinners/BarLoader";
import { Button, Form, Input, Modal, Popconfirm, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import customAxios from "../../utils/axios";
import { SERVER_URL } from "../../Constants";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const Tags = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [form] = Form.useForm();
  const formItemLayout = null;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Is Active",
      dataIndex: "active",
      key: "active",
      render: (number) => (
        <div>{number === true ? "Active" : "Not active"}</div>
      ),
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (text) =>
        text ? (
          <div
            style={{
              width: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
              borderRadius: "50%",
              height: "30px",
            }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        ) : (
          <></>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            type="primary"
            danger
            onClick={() => {
              editTag(record);
            }}
            disabled={!record?.status === "Active"}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            open={record.deleteActive}
            title="Are you sure？"
            okText="Delete"
            onConfirm={() => deleteRecord(record.id)}
            onCancel={() => CloseDeleteRecordAreYouSure(record.id)}
            cancelText="No"
          ></Popconfirm>
          <Button
            type="primary"
            danger
            onClick={() => OpenDeleteRecordAreYouSure(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = () => {
      customAxios.get(SERVER_URL + "/tags").then(function (res) {
        setData(res.data);
        setLoading(false);
      });
    };

    fetchData();
  }, []);

  const handleAddTagModal = (val) => {
    setIsAddTagModalOpen(!val);
  };

  const handleAddTagSubmit = async () => {
    const formData = form.getFieldsValue();

    if (isEdit) {
      const { data } = await customAxios.put(SERVER_URL + "/tags", formData);
      setData(data);
      setIsEdit(false);
    } else {
      const { data } = await customAxios.post(SERVER_URL + "/tags", formData);
      if (data.error) {
        window.alert(data.error);
        return;
      }
      setData(data);
    }

    setIsAddTagModalOpen(false);
  };

  function editTag(record) {
    setIsEdit(true);
    form.setFieldsValue({
      tag_id: record.id,
      title: record.title,
      active: record.active ? 1 : 0,
      icon: record.icon,
    });
    setIsAddTagModalOpen(true);
  }

  const OpenDeleteRecordAreYouSure = (tagId) => {
    const record = data.find((record) => record.id === tagId);
    if (record) {
      record.deleteActive = true;
      setData([...data]);
    }
  };

  const CloseDeleteRecordAreYouSure = (tagId) => {
    const record = data.find((record) => record.id === tagId);
    if (record) {
      record.deleteActive = false;
      setData([...data]);
    }
  };

  const deleteRecord = async (tagId) => {
    try {
      let { data } = await customAxios.delete(SERVER_URL + "/tags", {
        data: { tag_id: tagId },
      });

      if (data.error) {
        window.alert(data.error);
        return;
      } else {
        setData(data);
      }
    } catch (error) {
      console.log("--ERROR--", error);
    }
  };

  return (
    <div>
      <div>
        <Button
          type="primary"
          onClick={() => handleAddTagModal(isAddTagModalOpen)}
        >
          Add tag
        </Button>
      </div>

      {loading && (
        <BarLoader
          color="#1677ff"
          cssOverride={{
            display: "block",
            margin: "10vh auto",
            borderColor: "red",
          }}
          size={150}
        />
      )}
      {!loading && (
        <Table
          style={{ marginTop: "8px" }}
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{
            position: ["topRight", "bottomRight"],
          }}
        />
      )}

      <Modal
        title={isEdit ? "Edit tag" : "Add tag"}
        open={isAddTagModalOpen}
        onOk={handleAddTagSubmit}
        onCancel={() => handleAddTagModal(isAddTagModalOpen)}
      >
        <Form form={form} name="dynamic_rule" layout="vertical">
          <Form.Item
            {...formItemLayout}
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please tag title" }]}
          >
            <Input placeholder="Please tag title" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="active"
            label="Active"
            rules={[{ required: true, message: "Please select state" }]}
          >
            <Select
              name="active"
              options={[
                { value: 1, label: "True" },
                { value: 0, label: "False" },
              ]}
            />
          </Form.Item>

          <Form.Item {...formItemLayout} name="icon" label="Icon">
            <Input placeholder="Please input svg icon text" />
          </Form.Item>

          <Form.Item {...formItemLayout} name="tag_id">
            <Input type="hidden" placeholder="Please tag id" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Tags;
