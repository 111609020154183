import React from "react";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "../../utils/axios";

const ProtectedRoute = ({ children }) => {
  const token = window.localStorage.getItem("accessToken");

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }, []);

  if (!token) {
    return <Navigate to={"/login"} replace />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
