import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Select,
  Tag,
  message,
  Space,
  Popconfirm,
  Spin,
  DatePicker,
} from "antd";
import React, { useState, useEffect, useMemo, useRef } from "react";
import customAxios from "../../utils/axios";
import { countries, countriesRaw } from "../../Constants";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import debounce from "lodash/debounce";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import BarLoader from "react-spinners/BarLoader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;

const dateFormat = "YYYY-MM-DD";

const formItemLayout = null;

function DebounceSelect({
  fetchOptions,
  debounceTimeout = 800,
  label,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Form.Item
      {...formItemLayout}
      name="q"
      label={label}
      style={{
        width: "100%",
      }}
      rules={[{ required: true, message: "Please select user id" }]}
    >
      <Select
        labelInValue
        showSearch={true}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    </Form.Item>
  );
}

const User = () => {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState([
    {
      user_id: "",
      email: "",
      age: "",
      location: "",
      balance: "",
      last_login: "",
      whitelisted: "",
      created_at: "",
    },
  ]);
  const [totalData, setTotalData] = useState([]);
  const [totalUser, setTotalUser] = useState();
  const [userOption, setUserOption] = useState([]);
  const [fileInput, setFileInput] = useState(null);
  const [fileUploadPath, setFileUploadPath] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);

  const [sendingMessage, setSendingMessage] = useState(false);

  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddMsgModalOpen, setIsAddMsgModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const [settings, setSettings] = useState([
    {
      notification_frequency: "48",
      blocked_countries: "allow_all",
    },
  ]);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const [roleOptions, setRoleOptions] = useState([]);
  const [totalInfluencer, setTotalInfluencer] = useState([]);

  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const labelStyle = "original";
  const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
  };
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    let tempTimezoneOptions = [];
    for (let i = 0; i < options.length; i++) {
      tempTimezoneOptions.push({
        value: options[i].value,
        label: options[i].label,
      });
    }
    // console.log("tempTimezoneOptions", tempTimezoneOptions);
    setTimezoneOptions(tempTimezoneOptions);
  }, []);

  const [influencerData, setInfluencerData] = useState([
    {
      id: "",
      name: "",
      server_ip_1: "",
      server_ip_2: "",
      server_ip_3: "",
      server_ip_4: "",
      character: "",
      voice_id: "",
      voice_id_2: "",
      voice_model_id: "",
      voice_model_id_2: "",
      open_ai_model: "",
      link_search_model: "",
      intent_engine_model: "",
      prohibited_topics: "",
      temperature: "",
      top_p: "",
      top_k: "",
      min_length: "",
      max_new_tokens: "",
      notification_frequency: "",
      notification_message: "",
      tags: "",
      is_on_home: "",
      description: "",
      likes: "",
      comments: "",
      shares: "",
      followers: "",
      languages: "",
      location: "",
      gender: "",
      cover: "",
      image_examples_urls: [],
      image_examples_url: "",
      examples_prompts: "",
      image_generation_only: "",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const getInfluencerData = () => {
    customAxios.get(SERVER_URL + "/influencer").then(function (res) {
      setInfluencerData(res.data);
    });
  };

  const [sendInfluencerMessageOptions, setSendInfluencerMessageOptions] =
    useState([]);

  useEffect(() => {
    setSendInfluencerMessageOptions(
      influencerData
        .map((item) => ({ value: item.id, label: item.name }))
        .filter((item) => item.value !== null)
    );
  }, [influencerData]);

  const [sendToAll, setSendToAll] = useState(false);

  useEffect(() => {
    if (sendToAll) {
      form.setFieldsValue({ users: data.map((item) => item.id) });
    } else {
      form.setFieldsValue({ users: [] });
    }
  }, [sendToAll]);

  const [showUserEmails, setShowUserEmails] = useState(false);
  const [sendMessageOptions, setSendMessageOptions] = useState([]);

  useEffect(() => {
    if (showUserEmails) {
      // Show by email
      setSendMessageOptions(
        data
          .map((item) => ({ value: item.id, label: item.email }))
          .filter((item) => item.value !== null)
      );
    } else {
      // Show by user id
      setSendMessageOptions(
        data
          .map((item) => ({ value: item.id, label: item.id }))
          .filter((item) => item.value !== null)
      );
    }
  }, [showUserEmails, data]);

  const [messageApi, contextHolder] = message.useMessage();

  const [locationOptions] = useState(
    countriesRaw.map((item) => ({ value: item.label, label: item.label }))
  );

  const formItemLayout = null;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <div>{Math.round(Number(text) * 100) / 100}</div>,
    },
    {
      title: "Paid for posts amount",
      dataIndex: "paid_for_posts_amount",
      key: "paid_for_posts_amount",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Deposited amount",
      dataIndex: "deposited_amount",
      key: "deposited_amount",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "First deposit date",
      dataIndex: "first_deposit_date",
      key: "first_deposit_date",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Paid for subscription amount",
      dataIndex: "paid_for_subscription_amount",
      key: "paid_for_subscription_amount",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Subscribed since",
      dataIndex: "subscribed_since",
      key: "subscribed_since",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Spent on chat in date range",
      dataIndex: "spent_on_chat_in_date_range",
      key: "spent_on_chat_in_date_range",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Spent on custom images in date range",
      dataIndex: "spent_on_custom_images_in_date_range",
      key: "spent_on_custom_images_in_date_range",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      render: (role_id) => (
        <div>
          {roleOptions.map((item) => {
            if (item.value === role_id) {
              return item.label;
            }
          })}
        </div>
      ),
    },
  ];


  useEffect(() => {
    getInfluencerData();
    getUserData();
    getSettingsData();
  }, []);

  const getUserData = (current = 1, limit = 10, q = "") => {

    let start_date = form.getFieldValue("start_date");
    let end_date = form.getFieldValue("end_date");

    if (start_date === null || start_date == undefined) {
      start_date = "";
    } else {
      start_date = start_date.format("YYYY-MM-DD");
    }

    if (end_date === null || end_date == undefined) {
      end_date = "";
    } else {
      end_date = end_date.format("YYYY-MM-DD");
    }

    setLoading(true);
    customAxios
      .get(SERVER_URL + `/user-payment-stats?page=${current}&limit=${limit}&q=${q}&start_date=${start_date}&end_date=${end_date}`)
      .then(function (res) {
        const { users, influencers, role_options, total_data } = res.data;
        setData(users);
        setTotalData(total_data);
        setTotalUser(total_data.length > 0 ? total_data[0]["total_user"] : 0);
        setTotalInfluencer(influencers);
        setRoleOptions(role_options);
        setLoading(false);
      });
  };

  const downloadUserDataStats = async (q = "") => {

    messageApi.info("This may take a while, please wait for the download to start");

    try {
      let start_date = form.getFieldValue("start_date");
      let end_date = form.getFieldValue("end_date");
  
      if (start_date === null || start_date == undefined) {
        start_date = "";
      } else {
        start_date = start_date.format("YYYY-MM-DD");
      }
  
      if (end_date === null || end_date == undefined) {
        end_date = "";
      } else {
        end_date = end_date.format("YYYY-MM-DD");
      }
      let urls = SERVER_URL + `/user-payment-stats/csv?q=${q}&start_date=${start_date}&end_date=${end_date}`;

      const response = await customAxios.get(urls,
       {
         responseType: "blob" // Important to set this
       });

        // Check if not 200 status code
        if (response.status !== 200) {
          console.log(response)

          messageApi.error(response.data.error);
          return;
        }
      
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "user_payment_stats.csv");
        document.body.appendChild(link);
        link.click();

        messageApi.success("Download started successfully");
    } catch (err) {
      console.error(err);

      messageApi.error("Error downloading chat history");

    }
  };

  const getSettingsData = () => {
    customAxios.get(SERVER_URL + "/settings").then(function (res) {
      // console.log("res", res.data);
      if (res.data.length >= 1) {
        setSettings(res.data[0]);
      }
    });
  };

  return (
    <div>
      {contextHolder}
      <div>
        {loading && (
          <BarLoader
            color="#1677ff"
            cssOverride={{
              display: "block",
              margin: "10vh auto",
              borderColor: "red",
            }}
            size={150}
          />
        )}
        {!loading && (
          <div>
            <div style={{ display: "flex", gap: "20px", width: "100%" }}>
              <Form form={form} name="dynamic_rule" layout="vertical">
                <Form.Item
                  label="Start Date"
                  name="start_date"
                  id="start_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select start date",
                    },
                  ]}
                >
                  <DatePicker
                    maxDate={dayjs(new Date().toDateString(), dateFormat)}
                  />
                </Form.Item>

                <Form.Item
                  label="End Date"
                  name="end_date"
                  id="end_date"
                  rules={[
                    {
                      required: false,
                      message: "Please select end date",
                    },
                  ]}
                >
                  <DatePicker
                    maxDate={dayjs(new Date().toDateString(), dateFormat)}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    getUserData();
                  }}
                  style={{ marginRight: "20px" }}
                >
                  Search
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    downloadUserDataStats();
                  }}
                  style={{ marginRight: "20px" }}
                >
                  Download as CSV
                </Button>
              </Form>
            </div>
            <Table
              style={{ marginTop: "8px" }}
              columns={columns}
              dataSource={data}
              rowKey="id"
              onChange={(pagination) => {
                setCurrentPage(pagination.current);
                setLimit(pagination.pageSize);
                getUserData(pagination.current, pagination.pageSize);
              }}
              pagination={{
                position: ["topRight", "bottomRight"],
                current: currentPage,
                pageSize: limit,
                total: totalUser,
                pageSizeOptions: [10, 20, 50, 100, 500],
              }}
            />
          </div>
        )}
      </div>

    </div>
  );
};

export default User;
