import {Table, Button, Input, Form, message, Select, Space, Collapse} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import customAxios from "../../../utils/axios";
import { SERVER_URL } from "../../../Constants";

const ImageGenerationServices = ({formItemLayout, influencer_id, imageServicesData}) => {

  const [messageApi, contextHolder] = message.useMessage();

  const createImageGenerationService = async () => {
    // Make a POST request to create a new Image Generation Service (/influencer/image_generation_service)
    // If the request is successful, reload the page
    // If the request is unsuccessful, show an error message

    console.log("Creating new Image Generation Service");
    console.log(influencer_id)

    const { data } = await customAxios.post(SERVER_URL + '/influencer/image_generation_service', {
      influencer_id: influencer_id
    });
    if (data) {
      messageApi.open({
        type: "success",
        content: "Image Generation Service Created"
      });
      window.location.reload();
    }
    else {
      messageApi.open({
        type: "error",
        content: data.message
      });
    }

  }

  return (
    <>
      <h2>Image Generation Services:</h2>
      <span style={{color: "red"}}>nudity is in beta...</span>
      <Form.Item>
        <Button type="default" onClick={() => createImageGenerationService()}>
          Create New Service
        </Button>
      </Form.Item>
      {/*<Form.Item
        {...formItemLayout}
        name="sfw_image_service"
        label="SFW Image Service"
      >
        <select name="sfw_image_service" id="sfw_image_service" style={{width: "100%"}}>
          {
            imageServicesData.map((imageService, index) => {
              return <option value={imageService.name}>{imageService.name}</option>
            })
          }
        </select>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="nsfw_image_service"
        label="NSFW Image Service"
      >
        <select name="nsfw_image_service" id="nsfw_image_service" style={{width: "100%"}}>
          {
            imageServicesData.map((imageService, index) => {
              return <option value={imageService.name}>{imageService.name}</option>
            })
          }
        </select>
      </Form.Item>*/}
      {/*<Form.Item
        {...formItemLayout}
        name="imageGenerationResolution"
        label="Image Generation Resolution [WidthxHeight] (w and h must be in multiples of 8)"
      >
        <Input type="text" min={1}
               placeholder="Please input Image Generation Resolution in [WidthxHeight] (w and h must be in multiples of 8)"/>
        </Form.Item>*/}
      <Form.Item
        {...formItemLayout}
        name="imageGeneration_token_classname_user_placeholders"
        label="Image Generation Token Classname Placeholders (values entered by user that will be replaced with the token classname [e.g sks man]: format: Tyler Holland, Tyler, ...) [!IMPORTANT: ALWAYS START WITH THE LONGEST VERSION OF THE NAE!]"
      >
        <Input type="text" min={1} placeholder="Please input Image Generation Token Classname Placeholders"/>
      </Form.Item>
      {/*<Form.Item
        {...formItemLayout}
        name="imageGenerationTimeout"
        label="Image Generation Timeout"
      >
        <Input type="number" min={5} step={5} placeholder="Please input Image Generation Timeout"/>
      </Form.Item>*/}
      <Form.Item
        {...formItemLayout}
        name="mistralInterpretationTimeout"
        label="Mistral Image Prompt Interpretation Timeout (will retry up to 3 times if this time is exceeded)"
      >
        <Input type="number" min={5} step={5} placeholder="Please input Mistral Interpretation Timeout"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="mistral_interpreation_prompt"
        label="Mistral Interpretation Prompt"
      >
        <TextArea rows={10}/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="no_custom_image_message"
        label="No Custom Image Message"
      >
        <Input type="text" min={1} placeholder="Please input No Custom Image Message"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="custom_image_img_amount_message"
        label="Custom Image Image Amount Message"
      >
        <Input type="text" min={1} placeholder="Please input Custom Image Image Amount Message"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="custom_image_pre_message"
        label="Custom Image Pre Message"
      >
        <TextArea rows={5}/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="custom_image_generating_message"
        label="Custom Image Generating Message"
      >
        <Input type="text" min={1} placeholder="Please input Custom Image Generating Message"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="custom_image_post_message"
        label="Custom Image Post Message"
      >
        <Input type="text" min={1} placeholder="Please input Custom Image Post Message"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="imageGenerationErrorMSG"
        label="Custom Image Error Message"
      >
        <Input type="text" min={1} placeholder="Please input a Custom Image Error Message"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="customPic_hint_message"
        label="Custom Pic Hint Message"
      >
        <Input type="text" min={1} placeholder="Please input Custom Pic Hint Message"/>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="characterExcluded_hintMSG"
        label="Character Excluded Hint Message"
      >
        <Input type="text" min={1} placeholder="Please input Character Excluded Hint Message"/>
      </Form.Item>

    </>
  )
}

export default ImageGenerationServices;
