import React from "react";
import axios from "../../utils/axios";
import { Button } from "antd";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class ExportButton extends React.Component {
  downloadCSV = async () => {
    try {
      const response = await axios.get(SERVER_URL + "/chat_history/csv", {
        responseType: "blob" // Important to set this
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "all_chat_histories.csv");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return (
      <Button type="primary" onClick={this.downloadCSV}>
        Download Chat History
      </Button>
    );
  }
}

export default ExportButton;
